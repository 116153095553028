$theme-colors: ("black": #181616,
  "base": #f0f0f0,
  "heading": #247db5,
  "primary": #0a3c54,
  "secondary": #143042,
  "background": #062837,
  "shadow": #071f2b,
  "highlight": #ffcc00,
  "hyperlinkhover": #405994,
  "hyperlink": #7ea3e8,
  "btnhover": #0e5579,
  "danger": rgb(160, 40, 10),
  "warning": #920e0e,
  "success": #4e7400,
  "success-hover": #68970a,

  "disabled": #aca9a1a2,
  "light-muted": #ececec,
  "primary-muted": #a1be6118,
  "muted": #8e8e8e,
  "muted-dark": #6f7770,


  "skeleton-thumbnail-image": #0D415A,
  "skeleton-thumbnail-header": #1D4158,
  "skeleton-thumbnail-background": #0C3B51) !default;

$font-size: ("extra-small": 0.9rem,
  "small": 1rem,
  //for smaller text
  "standard": 1.2rem,
  // For all text
  "medium": 1.3rem,
  // For all other headings
  "medium-large": 1.4rem,
  "large": 1.6rem,
  "extra-large": 2rem,
  "extra-extra-large": 3rem,
  "extra-extra-extra-large": 4rem,
) !default;

$pad-mar: ("extra-extra-small": 0.3rem,
  "extra-small": 0.5rem,
  "small": 0.9rem,
  "small-medium": 1.1rem,
  "medium": 1.3rem,
  "medium-large": 1.5rem,
  "large": 1.9rem,
  "extra-large": 2.1rem,
  "extra-extra-large": 2.3rem,
  "space-one": 3rem,
  "space-two": 3.5rem,
  "space-three": 4.5rem,
  "space-four": 5rem,
  "space-five": 7rem,
) !default;

// Backup of old values before edit above to make it more compact
// $pad-mar: (
//   "extra-extra-small": 0.3rem,
//   "extra-small": 0.5rem,
//   "small": 0.8rem,
//   "small-medium": 1.1rem,
//   "medium": 1.4rem,
//   "medium-large": 1.7rem,
//   "large": 2.1rem,
//   "extra-large": 2.3rem,
//   "extra-extra-large": 2.6rem,
//   "space-one": 3rem,
//   "space-two": 4rem,
//   "space-three": 5rem,
//   "space-four": 7rem,
//   "space-five": 9rem,
// ) !default;

$pad-mar: ("extra-extra-small": 0.3rem,
  "extra-small": 0.5rem,
  "small": 0.8rem,
  "small-medium": 1.1rem,
  "medium": 1.4rem,
  "medium-large": 1.7rem,
  "large": 2.1rem,
  "extra-large": 2.3rem,
  "extra-extra-large": 2.6rem,
  "space-one": 3rem,
  "space-two": 4rem,
  "space-three": 5rem,
  "space-four": 7rem,
  "space-five": 9rem,
) !default;

//  https://xd.adobe.com/view/fc77c972-906e-48cd-a0f7-8efc77f7905b-a924/#access_token=eyJhbGciOiJSUzI1NiIsIng1dSI6Imltc19uYTEta2V5LTEuY2VyIn0.eyJpZCI6IjE2NDQyNDA5NDc3NTRfNDc3MzMwMDAtOTA2Yy00MTNhLTk5NmMtZDEyMDEzMWI0OTczX2V3MSIsInR5cGUiOiJhY2Nlc3NfdG9rZW4iLCJjbGllbnRfaWQiOiJDb21ldFdlYjEiLCJ1c2VyX2lkIjoiRTE1RTU2RTE1RjdCMTc3ODBBNDk1RkU3QEFkb2JlSUQiLCJhcyI6Imltcy1uYTEiLCJhYV9pZCI6IkUxNUU1NkUxNUY3QjE3NzgwQTQ5NUZFN0BBZG9iZUlEIiwiY3RwIjowLCJmZyI6IldGUzRWQ0U0VkxFNUlQVUNFTVFGUkhRQURRPT09PT09Iiwic2lkIjoiMTY0NDI0MDQ3NDQ1Nl8zODhlODcxZS00NjdlLTQxNmEtOTNlMi1iZTlkZDVkMjYyNDdfZXcxIiwibW9pIjoiMTRhNmVkMjkiLCJleHBpcmVzX2luIjoiODY0MDAwMDAiLCJzY29wZSI6IiIsImNyZWF0ZWRfYXQiOiIxNjQ0MjQwOTQ3NzU0In0.U3WH-eYRaFkALKUS2-gbx-HZsQ1smcJuJCJk8a4dZJTjFAz0OlEZ3ziC0EtREmujc8oAb8NzDOgf6ecRutVZBO_bhmlY4gDfddYdUCh9_EkUU0mo4T1vCnUccQKb--QXc2yBliMOFKNwYNqNnFbiU2G6N0XsbWFCeB0Ap6YB7_Mg2h45rHOBSKC3l8Ed6v8fUlCfWn5E8Z8QPZHmfmZWtJnBW4exIMmeILO8kP3yrL43WS51IZFlgBVAdBrLjq4v_YCblpQ1ITbceW1_PFH8uA8aV8_zh-sH4dLadoWjSCvgVtWnK3ysib9n5uMwhw0xdgs-X4G-7dSluwcCUSm6zA&token_type=bearer&expires_in=86399995

$font-weight-narrow: 200; //menu
$font-weight-standard: 300; //for all other
$font-weight-medium: 600; // for sub headers
$font-weight-bold: 800;
$font-weight-extra-bold: 1000; //for banner Black

$letter-spacing-standard: 1.5px;
$letter-spacing-menu: 3px;

$line-height-standard: 1.4;

$font-family-base: "creato" !default;
$font-family-menu: "galderglynn" !default;

// $font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
//   "Liberation Mono", "Courier New", monospace !default;

$border-radius-standard: 9px !default;
$border-radius-big: 13px !default;

$border-radius-containers-small: 1px;
$border-radius-containers: 2px;

$button-box-shadow: 0px 3px 7px #012236;
$box-shadow-big: 0px 20px 40px #071f2b;
$error-box-shadow: 0px 10px 20px #1b0303;
$success-box-shadow: 0px 10px 20px #001a05;
$button-delete-box-shadow: 0px 3px 7px #360101;
$button-successes-box-shadow: 0px 3px 7px #012901;
$button-successes-box-disabled: 0px 3px 7px #1d201d;

$container-width: 1440 !default;

$grid-cols: 12 !default;

$screen-extra-small-min: 300px;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

$grid-bp-small: 576 !default;
$grid-bp-medium: 768 !default;
$grid-bp-large: 992 !default;
$grid-bp-xlarge: 1200 !default;

$map-grid-props: ("": 0,
  "-small": $grid-bp-small,
  "-medium": $grid-bp-medium,
  "-large": $grid-bp-large,
  "-xlarge": $grid-bp-xlarge,
) !default;