// Gold for better laoding: https://www.youtube.com/watch?v=G0cOQ79WKZE

//creato

@font-face {
  font-family: "creato";
  src: local("creato"), url("./fonts/creato/CreatoDisplay-Thin.otf") format("truetype");
  font-weight: 200;
  font-style: normal;
  // font-display: optional;
}

@font-face {
  font-family: "creato";
  src: local("creato"), url("./fonts/creato/CreatoDisplay-Regular.otf") format("truetype");
  font-weight: 300;
  font-style: normal;
  // font-display: optional;
}

@font-face {
  font-family: "creato";
  src: local("creato"), url("./fonts/creato/CreatoDisplay-Bold.otf") format("truetype");
  font-weight: 600;
  font-style: normal;
  // font-display: optional;
}

@font-face {
  font-family: "creato";
  src: local("creato"), url("./fonts/creato/CreatoDisplay-ExtraBold.otf") format("truetype");
  font-weight: 800;
  font-style: normal;
  // font-display: optional;
}

@font-face {
  font-family: "creato";
  src: local("creato"), url("./fonts/creato/CreatoDisplay-Black.otf") format("truetype");
  font-weight: 1000;
  font-style: normal;
  // font-display: optional;
}

//For gardeling

//creato

@font-face {
  font-family: "galderglynn";
  src: local("galderglynn"), url("./fonts/galderglynn/galderglynn-titling-el.woff2") format("truetype");
  font-weight: 200;
  font-style: normal;
  // font-display: optional;
}

@font-face {
  font-family: "galderglynn";
  src: local("galderglynn"), url("./fonts/galderglynn/galderglynn-titling-lt.woff2") format("truetype");
  font-weight: 300;
  font-style: normal;
  // font-display: optional;
}

@font-face {
  font-family: "galderglynn";
  src: local("galderglynn"), url("./fonts/galderglynn/galderglynn-titling-rg.woff2") format("truetype");
  font-weight: 600;
  font-style: normal;
  // font-display: optional;
}

@font-face {
  font-family: "galderglynn";
  src: local("galderglynn"), url("./fonts/galderglynn/galderglynn-titling-bd.woff2") format("truetype");
  font-weight: 800;
  font-style: normal;
  // font-display: optional;
}

// @font-face {
//   font-family: "galderglynn";
//   src: url("/public/galderglynn/galderglynn-titling-bl.woff2") format("truetype");
//   font-weight: 1000;
//   font-style: normal;
//   // font-display: optional;
// }


* {
  line-height: $line-height-standard;
  color: color("base");
  font-family: $font-family-base, sans-serif;
  font-weight: $font-weight-standard;
  letter-spacing: $letter-spacing-standard;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: color("heading");
  font-size: font-size("medium");
  // display: inline-block;
  word-wrap: break-word;
  // font-weight: $font-weight-medium;
  font-weight: $font-weight-bold;
  margin-bottom: pad-mar("medium");

  .highlighter {
    font-weight: $font-weight-medium;
    font-size: font-size("medium");
  }
}

.highlighter {
  color: color("highlight");
}

.no-banner-heading {
  text-align: center;
  margin: pad-mar("space-three") 0 pad-mar("space-one") 0;
}

h1 {
  font-weight: $font-weight-extra-bold;
  font-size: font-size("extra-large");
  margin-bottom: pad-mar("space-one");

  .highlighter {
    font-weight: $font-weight-extra-bold;
    font-size: font-size("extra-large");
  }
}

h2 {
  font-size: font-size("large");
  margin-bottom: pad-mar("medium-large");
}

p {
  margin-bottom: pad-mar("small-medium");
  font-size: font-size("standard");
}

ul,
ol,
dl {
  li {
    font-size: font-size("standard");
    margin-bottom: pad-mar("extra-extra-small");
  }
}

a {
  transition: all 0.3s ease-in-out;
  color: color("hyperlink");
  cursor: pointer;

  &:hover {
    transition: all 0.3s ease-in-out;
    color: color("hyperlinkhover");
  }
}

.icon-link {
  color: color("base");

  &:hover {
    color: color("highlight");
  }
}

.anchor-tag {
  text-decoration: underline;
  color: color("hyperlink");
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  // text-decoration: underline;
  &:hover {
    transition: all 0.3s ease-in-out;
    color: color("hyperlinkhover");
  }
}

.reset-anchor-tag,
.reset-anchor-tag:hover,
.reset-anchor-tag:focus,
.reset-anchor-tag:active {
  text-decoration: none;
  color: inherit;
}

.close-mark {
  cursor: pointer;

  i {
    color: color("danger");
    font-size: font-size("medium");
  }

  &:hover {
    i {
      color: color("warning");
    }
  }
}

.hover-menu-item {
  font-family: $font-family-menu, Arial;
  transform: scale(1);
  position: relative;
  cursor: pointer;
  font-weight: $font-weight-narrow;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  display: inline-block;

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 0%;
    left: 0;
    border-bottom: 2px solid color("highlight");
    transform: scale(1);
    transition: all 0.3s 0.35s ease-in-out;
  }

  &:hover {
    transform: scale(1.1);
    font-family: $font-family-menu, Arial;
    font-weight: $font-weight-standard;

    &:before {
      width: 80%;
      left: 0;
      transform: scale(1);
    }
  }
}

.hover-menu-item-active {
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  display: inline-block;

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 0%;
    left: 0;
    border-bottom: 2px solid color("highlight");
    transform: scale(1);
    transition: all 0.3s 0.35s ease-in-out;
    width: 80%;
    left: 0;
    transform: scale(1);
  }

  transform: scale(1.1);
  font-family: $font-family-menu,
  Arial;
  font-weight: $font-weight-standard;
}

strong,
b {
  font-weight: $font-weight-medium;
}

@media only screen and (max-width: 768px) {
  // .hover-menu-item {
  //   transform: scale(1);
  //   &:hover {
  //     transform: scale(1.1);
  //     font-weight: $font-weight-standard;
  //     &:before {
  //       width: 80%;
  //       left: 0;
  //       transform: scale(1);
  //     }
  //   }
  // }
  // .hover-menu-item-active {
  //   transform: scale(1.1);
  // }

  h1 {
    font-weight: $font-weight-extra-bold;
    font-size: font-size("large");
    margin-bottom: pad-mar("extra-large");

    .highlighter {
      font-weight: $font-weight-extra-bold;
      //   font-size: font-size("extra-large");
    }
  }
}