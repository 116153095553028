#summary-card {
  .summery-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    // align-items: center;
    margin-bottom: pad-mar("medium-large");

    .summery-title {
      font-size: font-size("small");
      min-width: 160px;
      margin: 0;
    }

    .summery-text {
      font-size: font-size("small");

      #technique-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: pad-mar("small");

        span {
          font-size: font-size("small");
        }
      }
    }

    .summery-links {
      word-wrap: break-word;
      cursor: pointer;
      text-align: center;
      display: flex;
      flex-direction: row;
      gap: pad-mar("medium");

      .webpage-item {

        li,
        i {
          transition: all 0.2s ease-in-out;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          color: color("hyperlink");
        }

        &:hover {

          li,
          i {
            transition: all 0.2s ease-in-out;
            color: color("hyperlinkhover");
          }
        }

        .webpage-name {
          margin-bottom: pad-mar("extra-small");
          font-size: font-size("extra-small");
        }

        .webpage-icon {
          margin-bottom: 0;

          i {
            font-size: font-size("standard");
          }
        }
      }
    }
  }
}

// Idea for large screens
#technologies-card {
  li {
    b {
      color: color("heading");
    }

    margin-bottom: pad-mar("small");
  }
}

// Idea for small screens
#technologies-card {
  li {
    b {
      display: block;
      margin-bottom: pad-mar("extra-extra-small");
    }
  }
}

@media only screen and (max-width: 576px) {
  #summary-card {
    .summery-item {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      // margin: pad-mar("medium") 0;
      .summery-title {
        min-width: unset;
        margin: 0;
      }

      .summery-text {
        margin-top: pad-mar("extra-small");
      }
    }
  }

}