.popup-container {
  height: 80%;
  max-height: 500px;
  width: 20%;
  overflow-y: auto;
  position: fixed;
  top: 110px;
  right: 2rem;
  z-index: 99999999;
  .popup-scrolling {
    min-height: 100%;
    .message-container {
      display: flex;
      // Nasty, if someone sees this, please ignore!
      flex-direction: column-reverse;
      flex-wrap: wrap;
      .error-card {
        box-shadow: $error-box-shadow;
        background-color: color("warning");
      }
      .success-card {
        box-shadow: $success-box-shadow;
        background-color: color("success");
      }
      .message-item {
        border-radius: $border-radius-standard;
        margin: pad-mar("small-medium") 0;
        padding: pad-mar("extra-small") pad-mar("small");
        position: relative;
        .message-close-icon {
          cursor: pointer;
          position: absolute;
          right: pad-mar("small");
          top: pad-mar("extra-small");
          i {
            color: color("black");
          }
          &:hover {
            i {
              color: color("base");
            }
          }
        }
      }
    }
  }
}

.popup-message {
  font-size: font-size("medium");
  font-weight: $font-weight-bold;
  padding: pad-mar("small") 0;
}

@media only screen and (max-width: 1500px) {
  .popup-container {
    width: 40%;
    top: 200px;
  }
}

@media only screen and (max-width: 768px) {
  .popup-container {
    width: 70%;
    top: 300px;
  }
}
