.banner-container {
  .banner-background {
    // min-height: 80vh;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    // image-rendering: -moz-crisp-edges; /* Firefox */
    // image-rendering: -o-crisp-edges; /* Opera */
    // image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    // image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    /* IE (non-standard property) */
    // These are important because all banners use these values and to make sure dynamic banners created by react doesn't overwrite these values
    background-size: cover !important;
    // These two are needed for fixed... But height is problem.
    // background-size: 100% auto !important;
    // background-attachment: fixed !important;
    background-repeat: no-repeat !important;
    background-position: top !important;
    background-color: #121c2152;
    background: linear-gradient((180deg, #121c21A5 0%, #021923 100%));
    display: flex;
    justify-content: center;
    align-items: center;

    .home-banner-content {
      flex: 1;
      padding: 11rem 0 !important;

      .home-banner-info {
        padding: 0 pad-mar("space-two");

        h1 {
          font-size: font-size("extra-extra-extra-large");
          color: color("base");
          margin: 0;

          .highlighter {
            font-size: font-size("extra-extra-extra-large");
          }
        }
      }
    }

    .banner-content {
      flex: 1;
      padding: pad-mar("space-three") 0;

      .banner-info {
        width: 90%;
        padding: 0 pad-mar("space-two");
        margin-bottom: pad-mar("space-three");

        h1 {
          color: color("base");
          margin-bottom: pad-mar("space-two");
          font-size: font-size("extra-extra-large");

          .highlighter {
            font-size: font-size("extra-extra-large");
          }
        }

        p {
          font-size: font-size("large");
        }
      }
    }
  }

  #home-banner-image {
    background: linear-gradient((180deg, #121c21A5 0%, #021923 100%)),
      url("/public/assets/banner.jpg");
  }
}

#about-banner-image {
  background: linear-gradient((180deg, #121c21A5 0%, #021923 100%)),
    url("/public/assets/banner.jpg");
}


// // reduces height banner on 4k or taller height screens
// @media (min-height: 1200px) {
//   .banner-container {
//     .banner-background {
//       min-height: 40vh;
//     }
//   }
// }

@media only screen and (min-width: 1650px) {
  .banner-container {
    .banner-background {
      .home-banner-content {
        flex: 1;
        padding: pad-mar("space-three") 0;

        .home-banner-info {
          h1 {
            font-size: font-size("extra-extra-extra-large");
            color: color("base");
            margin: 0;

            .highlighter {
              font-size: font-size("extra-extra-extra-large");
            }
          }
        }
      }

      .banner-content {
        flex: 1;
        padding: pad-mar("space-three") 0;

        .banner-info {
          width: 90%;
          padding: 0;
          margin-bottom: pad-mar("space-three");

          h1 {
            color: color("base");
            margin-bottom: pad-mar("space-two");
            font-size: font-size("extra-extra-large");

            .highlighter {
              font-size: font-size("extra-extra-large");
            }
          }

          p {
            font-size: font-size("large");
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .banner-container {
    .banner-background {

      // min-height: 30vh;
      .home-banner-content {
        .home-banner-info {
          padding: 0 pad-mar("space-one");

          // margin-bottom: pad-mar("space-two");
          h1 {
            font-size: font-size("extra-extra-large");
            margin: 0;

            .highlighter {
              font-size: font-size("extra-extra-large");
            }
          }
        }
      }

      .banner-content {
        padding: pad-mar("space-two") 0;

        .banner-info {
          width: 100%;
          padding: 0 pad-mar("space-one");
          margin-bottom: pad-mar("space-two");

          h1 {
            margin-bottom: pad-mar("space-one");
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .banner-container {
    .banner-background {

      // min-height: 30vh;
      .home-banner-content {
        padding: 7rem 0 !important;

        .home-banner-info {
          padding: 0 pad-mar("large");

          // margin-bottom: pad-mar("space-two");
          h1 {
            font-size: font-size("extra-large");

            // margin-bottom: pad-mar("large");
            .highlighter {
              font-size: font-size("extra-large");
            }
          }
        }
      }

      .banner-content {
        padding: pad-mar("space-one") 0;

        .banner-info {
          width: 100%;
          padding: 0 pad-mar("large");
          margin-bottom: pad-mar("space-two");

          h1 {
            font-size: font-size("extra-large");
            margin-bottom: pad-mar("large");

            .highlighter {
              font-size: font-size("extra-large");
            }
          }

          p {
            font-size: font-size("medium");
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 300px) {
  .banner-container {
    .banner-background {
      .home-banner-content {
        .home-banner-info {
          h1 {
            font-size: font-size("extra-large");
            margin-bottom: 0;

            .highlighter {
              font-size: font-size("extra-large");
            }
          }
        }
      }

      .banner-content {
        .banner-info {
          h1 {
            font-size: font-size("large");

            .highlighter {
              font-size: font-size("large");
            }
          }

          p {
            font-size: font-size("standard");
          }
        }
      }
    }
  }
}