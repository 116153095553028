* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

#root {
  position: relative;
  min-height: 100vh;
}

#layout-container {
  padding-bottom: 270px;
  padding-top: 70px;
  background-image: url("/public/assets/background-web.svg");
  background-position: center top;
  background-size: 100% auto;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
  overflow: hidden;
  width: 100%;
  scrollbar-width: none;
}

body {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: #062837;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.unordered-list-reset {
  list-style-type: none;
}
.unordered-list-reset .open-location-button {
  margin-bottom: 0;
}

.button-reset {
  all: unset;
  cursor: pointer;
  color: #f0f0f0;
  font-size: 1.2rem;
  font-family: "creato";
}

/* I will not get a tiny api key or what this plugging is trying to sell me..! */
.tox-notifications-container {
  display: none;
}

img {
  width: 100%;
}

@media screen and (max-width: 992px) {
  #layout-container {
    padding-top: 60px;
    padding-bottom: 340px;
    background-image: url("/public/assets/background-table.svg");
  }
}
@media screen and (max-width: 576px) {
  #layout-container {
    padding-top: 100px;
    padding-bottom: 500px;
  }
}
@media screen and (max-width: 300px) {
  #layout-container {
    width: 300px;
    padding-bottom: 600px;
  }
}
:root {
  --black-color: #181616;
  --base-color: #f0f0f0;
  --heading-color: #247db5;
  --primary-color: #0a3c54;
  --secondary-color: #143042;
  --background-color: #062837;
  --shadow-color: #071f2b;
  --highlight-color: #ffcc00;
  --hyperlinkhover-color: #405994;
  --hyperlink-color: #7ea3e8;
  --btnhover-color: #0e5579;
  --danger-color: rgb(160, 40, 10);
  --warning-color: #920e0e;
  --success-color: #4e7400;
  --success-hover-color: #68970a;
  --disabled-color: rgba(172, 169, 161, 0.6352941176);
  --light-muted-color: #ececec;
  --primary-muted-color: rgba(161, 190, 97, 0.0941176471);
  --muted-color: #8e8e8e;
  --muted-dark-color: #6f7770;
  --skeleton-thumbnail-image-color: #0D415A;
  --skeleton-thumbnail-header-color: #1D4158;
  --skeleton-thumbnail-background-color: #0C3B51;
}

@font-face {
  font-family: "creato";
  src: local("creato"), url("./fonts/creato/CreatoDisplay-Thin.otf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "creato";
  src: local("creato"), url("./fonts/creato/CreatoDisplay-Regular.otf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "creato";
  src: local("creato"), url("./fonts/creato/CreatoDisplay-Bold.otf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "creato";
  src: local("creato"), url("./fonts/creato/CreatoDisplay-ExtraBold.otf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "creato";
  src: local("creato"), url("./fonts/creato/CreatoDisplay-Black.otf") format("truetype");
  font-weight: 1000;
  font-style: normal;
}
@font-face {
  font-family: "galderglynn";
  src: local("galderglynn"), url("./fonts/galderglynn/galderglynn-titling-el.woff2") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "galderglynn";
  src: local("galderglynn"), url("./fonts/galderglynn/galderglynn-titling-lt.woff2") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "galderglynn";
  src: local("galderglynn"), url("./fonts/galderglynn/galderglynn-titling-rg.woff2") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "galderglynn";
  src: local("galderglynn"), url("./fonts/galderglynn/galderglynn-titling-bd.woff2") format("truetype");
  font-weight: 800;
  font-style: normal;
}
* {
  line-height: 1.4;
  color: #f0f0f0;
  font-family: "creato", sans-serif;
  font-weight: 300;
  letter-spacing: 1.5px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #247db5;
  font-size: 1.3rem;
  word-wrap: break-word;
  font-weight: 800;
  margin-bottom: 1.3rem;
}
h1 .highlighter,
h2 .highlighter,
h3 .highlighter,
h4 .highlighter,
h5 .highlighter,
h6 .highlighter {
  font-weight: 600;
  font-size: 1.3rem;
}

.highlighter {
  color: #ffcc00;
}

.no-banner-heading {
  text-align: center;
  margin: 4.5rem 0 3rem 0;
}

h1 {
  font-weight: 1000;
  font-size: 2rem;
  margin-bottom: 3rem;
}
h1 .highlighter {
  font-weight: 1000;
  font-size: 2rem;
}

h2 {
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
}

p {
  margin-bottom: 1.1rem;
  font-size: 1.2rem;
}

ul li,
ol li,
dl li {
  font-size: 1.2rem;
  margin-bottom: 0.3rem;
}

a {
  transition: all 0.3s ease-in-out;
  color: #7ea3e8;
  cursor: pointer;
}
a:hover {
  transition: all 0.3s ease-in-out;
  color: #405994;
}

.icon-link {
  color: #f0f0f0;
}
.icon-link:hover {
  color: #ffcc00;
}

.anchor-tag {
  text-decoration: underline;
  color: #7ea3e8;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.anchor-tag:hover {
  transition: all 0.3s ease-in-out;
  color: #405994;
}

.reset-anchor-tag,
.reset-anchor-tag:hover,
.reset-anchor-tag:focus,
.reset-anchor-tag:active {
  text-decoration: none;
  color: inherit;
}

.close-mark {
  cursor: pointer;
}
.close-mark i {
  color: rgb(160, 40, 10);
  font-size: 1.3rem;
}
.close-mark:hover i {
  color: #920e0e;
}

.hover-menu-item {
  font-family: "galderglynn", Arial;
  transform: scale(1);
  position: relative;
  cursor: pointer;
  font-weight: 200;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  display: inline-block;
}
.hover-menu-item::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 0%;
  left: 0;
  border-bottom: 2px solid #ffcc00;
  transform: scale(1);
  transition: all 0.3s 0.35s ease-in-out;
}
.hover-menu-item:hover {
  transform: scale(1.1);
  font-family: "galderglynn", Arial;
  font-weight: 300;
}
.hover-menu-item:hover:before {
  width: 80%;
  left: 0;
  transform: scale(1);
}

.hover-menu-item-active {
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  display: inline-block;
  transform: scale(1.1);
  font-family: "galderglynn", Arial;
  font-weight: 300;
}
.hover-menu-item-active::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 0%;
  left: 0;
  border-bottom: 2px solid #ffcc00;
  transform: scale(1);
  transition: all 0.3s 0.35s ease-in-out;
  width: 80%;
  left: 0;
  transform: scale(1);
}

strong,
b {
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-weight: 1000;
    font-size: 1.6rem;
    margin-bottom: 2.1rem;
  }
  h1 .highlighter {
    font-weight: 1000;
  }
}
.app-max-container {
  max-width: 1540px;
  margin: 0 auto;
}

.card-max-container {
  max-width: 2200px;
  margin: 0 auto;
}

@media only screen and (min-width: 2200px) {
  .card-max-container {
    border-radius: 9px;
  }
}
.banner-container .banner-background {
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: top !important;
  background-color: rgba(18, 28, 33, 0.3215686275);
  background: linear-gradient(180deg, rgba(18, 28, 33, 0.6470588235) 0%, #021923 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-container .banner-background .home-banner-content {
  flex: 1;
  padding: 11rem 0 !important;
}
.banner-container .banner-background .home-banner-content .home-banner-info {
  padding: 0 3.5rem;
}
.banner-container .banner-background .home-banner-content .home-banner-info h1 {
  font-size: 4rem;
  color: #f0f0f0;
  margin: 0;
}
.banner-container .banner-background .home-banner-content .home-banner-info h1 .highlighter {
  font-size: 4rem;
}
.banner-container .banner-background .banner-content {
  flex: 1;
  padding: 4.5rem 0;
}
.banner-container .banner-background .banner-content .banner-info {
  width: 90%;
  padding: 0 3.5rem;
  margin-bottom: 4.5rem;
}
.banner-container .banner-background .banner-content .banner-info h1 {
  color: #f0f0f0;
  margin-bottom: 3.5rem;
  font-size: 3rem;
}
.banner-container .banner-background .banner-content .banner-info h1 .highlighter {
  font-size: 3rem;
}
.banner-container .banner-background .banner-content .banner-info p {
  font-size: 1.6rem;
}
.banner-container #home-banner-image {
  background: linear-gradient(180deg, rgba(18, 28, 33, 0.6470588235) 0%, #021923 100%), url("/public/assets/banner.jpg");
}

#about-banner-image {
  background: linear-gradient(180deg, rgba(18, 28, 33, 0.6470588235) 0%, #021923 100%), url("/public/assets/banner.jpg");
}

@media only screen and (min-width: 1650px) {
  .banner-container .banner-background .home-banner-content {
    flex: 1;
    padding: 4.5rem 0;
  }
  .banner-container .banner-background .home-banner-content .home-banner-info h1 {
    font-size: 4rem;
    color: #f0f0f0;
    margin: 0;
  }
  .banner-container .banner-background .home-banner-content .home-banner-info h1 .highlighter {
    font-size: 4rem;
  }
  .banner-container .banner-background .banner-content {
    flex: 1;
    padding: 4.5rem 0;
  }
  .banner-container .banner-background .banner-content .banner-info {
    width: 90%;
    padding: 0;
    margin-bottom: 4.5rem;
  }
  .banner-container .banner-background .banner-content .banner-info h1 {
    color: #f0f0f0;
    margin-bottom: 3.5rem;
    font-size: 3rem;
  }
  .banner-container .banner-background .banner-content .banner-info h1 .highlighter {
    font-size: 3rem;
  }
  .banner-container .banner-background .banner-content .banner-info p {
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 1200px) {
  .banner-container .banner-background .home-banner-content .home-banner-info {
    padding: 0 3rem;
  }
  .banner-container .banner-background .home-banner-content .home-banner-info h1 {
    font-size: 3rem;
    margin: 0;
  }
  .banner-container .banner-background .home-banner-content .home-banner-info h1 .highlighter {
    font-size: 3rem;
  }
  .banner-container .banner-background .banner-content {
    padding: 3.5rem 0;
  }
  .banner-container .banner-background .banner-content .banner-info {
    width: 100%;
    padding: 0 3rem;
    margin-bottom: 3.5rem;
  }
  .banner-container .banner-background .banner-content .banner-info h1 {
    margin-bottom: 3rem;
  }
}
@media only screen and (max-width: 576px) {
  .banner-container .banner-background .home-banner-content {
    padding: 7rem 0 !important;
  }
  .banner-container .banner-background .home-banner-content .home-banner-info {
    padding: 0 1.9rem;
  }
  .banner-container .banner-background .home-banner-content .home-banner-info h1 {
    font-size: 2rem;
  }
  .banner-container .banner-background .home-banner-content .home-banner-info h1 .highlighter {
    font-size: 2rem;
  }
  .banner-container .banner-background .banner-content {
    padding: 3rem 0;
  }
  .banner-container .banner-background .banner-content .banner-info {
    width: 100%;
    padding: 0 1.9rem;
    margin-bottom: 3.5rem;
  }
  .banner-container .banner-background .banner-content .banner-info h1 {
    font-size: 2rem;
    margin-bottom: 1.9rem;
  }
  .banner-container .banner-background .banner-content .banner-info h1 .highlighter {
    font-size: 2rem;
  }
  .banner-container .banner-background .banner-content .banner-info p {
    font-size: 1.3rem;
  }
}
@media only screen and (max-width: 300px) {
  .banner-container .banner-background .home-banner-content .home-banner-info h1 {
    font-size: 2rem;
    margin-bottom: 0;
  }
  .banner-container .banner-background .home-banner-content .home-banner-info h1 .highlighter {
    font-size: 2rem;
  }
  .banner-container .banner-background .banner-content .banner-info h1 {
    font-size: 1.6rem;
  }
  .banner-container .banner-background .banner-content .banner-info h1 .highlighter {
    font-size: 1.6rem;
  }
  .banner-container .banner-background .banner-content .banner-info p {
    font-size: 1.2rem;
  }
}
button,
input[type=submit],
input[type=reset] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: #071f2b;
  margin: 0.3rem 0;
  padding: 0.5rem 0.9rem;
  box-shadow: 0px 3px 7px #012236;
  border-radius: 13px;
}
button:hover,
input[type=submit]:hover,
input[type=reset]:hover {
  background-color: #0e5579;
}

button {
  font-size: 1.2rem;
  font-weight: 600;
  background-color: #071f2b;
  margin: 0.3rem 0;
  padding: 0.5rem 0.9rem;
  max-width: 200px;
  width: 100%;
}
button:hover {
  background-color: #0e5579;
}

.delete-button {
  background-color: rgb(160, 40, 10);
  box-shadow: 0px 3px 7px #360101;
}
.delete-button:hover {
  background-color: #920e0e;
}

.submit-button {
  background-color: #4e7400;
  box-shadow: 0px 3px 7px #012901;
}
.submit-button:hover {
  background-color: #68970a;
}

.go-back {
  font-size: 1.2rem;
  font-weight: 800;
  color: #f0f0f0;
  margin: 0.3rem 0;
  padding: 0.5rem 0.9rem;
}
.go-back:hover {
  color: #0e5579;
}

button:disabled,
button[disabled] {
  background: transparent linear-gradient(180deg, rgba(172, 169, 161, 0.6352941176) 100%, #8e8e8e 0%) 0% 0% no-repeat padding-box;
  color: #181616;
  opacity: 0.7;
  box-shadow: 0px 3px 7px #1d201d;
}
button:disabled:hover,
button[disabled]:hover {
  cursor: not-allowed;
  background-color: #6f7770;
}

.remove-disabled-styling {
  background: transparent;
}

button:disabled::before,
button[disabled]::before {
  color: #8e8e8e;
}

header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.1rem 2.3rem;
  background-color: #143042;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999999;
}
header .logo {
  font-family: "creato", sans-serif;
  font-size: 1.6rem;
  font-weight: 1000;
}
header .logo:hover {
  color: #405994;
}
header nav {
  flex: 1;
  justify-content: end;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.9rem;
  align-items: center;
}
header nav a {
  color: #f0f0f0;
  text-decoration: none;
  font-family: "galderglynn", Arial;
  font-weight: 200;
  font-size: 1.2rem;
  letter-spacing: 3px;
}

@media only screen and (max-width: 768px) {
  header {
    padding: 0.9rem 1.3rem;
    background-color: #143042;
  }
  header nav {
    gap: 1.5rem;
  }
  header nav a {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 576px) {
  header {
    padding: 1.1rem 0.9rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }
  header .logo {
    font-size: 1.3rem;
    text-align: center;
  }
  header nav {
    flex-wrap: wrap;
    gap: 1.1rem;
    margin-top: 1.1rem;
    justify-content: center;
  }
}
@media only screen and (max-width: 300px) {
  header {
    min-width: 299px;
  }
}
@media only screen and (max-width: 1500px) {
  #admin-header {
    flex-direction: column;
    flex-wrap: wrap;
  }
  #admin-header nav {
    margin-top: 0.9rem;
    justify-content: space-evenly;
    gap: 1.1rem;
  }
}
.card {
  background-color: #0a3c54;
  box-shadow: 0px 20px 40px #071f2b;
  padding: 2.1rem 3.5rem;
}

.card-large {
  background-color: #0a3c54;
  box-shadow: 0px 20px 40px #071f2b;
  padding: 5rem 6rem;
}

.card-no-side-padding {
  background-color: #0a3c54;
  box-shadow: 0px 20px 40px #071f2b;
  padding: 2.1rem 0;
}

.card-no-padding {
  background-color: #0a3c54;
  box-shadow: 0px 20px 40px #071f2b;
}

.card-small {
  background-color: #0a3c54;
  box-shadow: 0px 20px 40px #071f2b;
  padding: 1.3rem 3.5rem;
}

.card-left {
  border-radius: 0 9px 9px 0;
  width: 75%;
  margin-right: 25%;
}

.card-right {
  border-radius: 9px 0 0 9px;
  width: 75%;
  margin-left: 25%;
}

.card-center {
  border-radius: 9px;
}

.card-center-fullwidth {
  border-radius: 0;
}

@media only screen and (min-width: 1540px) {
  .card-center-fullwidth {
    border-radius: 9px;
  }
  .card-right {
    border-radius: 9px;
  }
  .card-left {
    border-radius: 9px;
  }
  .card-center {
    border-radius: 9px;
  }
}
@media only screen and (max-width: 1200px) {
  .card {
    padding: 1.9rem 3rem;
  }
  .card-small {
    padding: 1.1rem 3rem;
  }
  .card-large {
    background-color: #0a3c54;
    box-shadow: 0px 20px 40px #071f2b;
    padding: 3rem 4rem;
  }
  .card-left {
    width: 85%;
    margin-right: 15%;
  }
  .card-right {
    width: 85%;
    margin-left: 15%;
  }
}
@media only screen and (max-width: 576px) {
  .card {
    padding: 1.9rem 1.9rem;
  }
  .card-small {
    padding: 1.3rem 1.9rem;
  }
  .card-left {
    width: 95%;
    margin-right: 5%;
  }
  .card-right {
    width: 95%;
    margin-left: 5%;
  }
  .card-large {
    background-color: #0a3c54;
    box-shadow: 0px 20px 40px #071f2b;
    padding: 2rem 3rem;
  }
}
.thumbnail-cards-container {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0 3.5rem;
  gap: 1.9rem 1.5rem;
}
.thumbnail-cards-container .thumbnail-card {
  transition: all 0.4s ease-in-out;
  transform: scale(1);
  height: 300px;
  cursor: pointer;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.thumbnail-cards-container .thumbnail-card::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.thumbnail-cards-container .thumbnail-card .thumbnail-header-name {
  font-size: 1.3rem;
  padding: 1.1rem;
  padding-bottom: 1.5rem;
  color: #f0f0f0;
  margin: 0;
}
.thumbnail-cards-container .thumbnail-card .thumbnail-technique-container {
  border-radius: 9px 0 0 9px;
  width: 90%;
  margin-left: 10%;
  padding: 1.1rem;
  padding-bottom: 1.5rem;
  position: absolute;
  right: -470px;
  bottom: 1.3rem;
  transition: all 0.6s ease-in-out;
}
.thumbnail-cards-container .thumbnail-card .thumbnail-technique-container .thumbnail-header-techniques,
.thumbnail-cards-container .thumbnail-card .thumbnail-technique-container .thumbnail-metainfo {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.thumbnail-cards-container .thumbnail-card .thumbnail-technique-container .thumbnail-keyword-container {
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.thumbnail-cards-container .thumbnail-card .thumbnail-technique-container .thumbnail-keyword-container .thumbnail-keyword-item {
  font-size: 0.9rem;
}
.thumbnail-cards-container .thumbnail-card img {
  object-fit: cover;
  width: 100%;
}
.thumbnail-cards-container .thumbnail-card:hover {
  transition: all 0.4s ease-in-out;
  z-index: 99999;
  transform: scale(1.2);
  height: 300px;
}
.thumbnail-cards-container .thumbnail-card:hover .thumbnail-technique-container {
  transition: all 0.6 ease-in-out;
  position: absolute;
  right: 0;
  bottom: 1.3rem;
}

#records-container {
  word-break: break-all;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0 3.5rem;
  gap: 1.9rem 1.5rem;
}
#records-container .record-card {
  margin-bottom: 1.9rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#records-container .record-card .record-header-name {
  word-wrap: break-word;
  font-size: 1.3rem;
  margin-bottom: 1.1rem;
}
#records-container .record-card .record-metainfo {
  word-wrap: break-word;
  font-size: 1rem;
  margin-bottom: 0.9rem;
}

@media screen and (max-width: 1200px) {
  .thumbnail-cards-container {
    padding: 0 3rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
  #records-container {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 3rem;
  }
}
@media screen and (max-width: 992px) {
  .thumbnail-cards-container {
    grid-template-columns: 1fr 1fr;
  }
  #records-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 576px) {
  #records-container {
    grid-template-columns: 1fr;
    padding: 0 1.9rem;
  }
  .thumbnail-cards-container {
    grid-template-columns: 1fr;
    padding: 0 1.9rem;
  }
  .thumbnail-cards-container .thumbnail-card:hover {
    transform: scale(1.1);
  }
}
@media screen and (max-width: 300px) {
  .thumbnail-cards-container .thumbnail-card {
    height: 250px;
  }
  .thumbnail-cards-container .thumbnail-card .thumbnail-header-name {
    font-size: 1.3rem;
    padding: 1.3rem;
    margin: 0;
  }
}
#searchbar-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.1rem 2.1rem;
  align-items: center;
  justify-content: flex-start;
  padding: 0 3.5rem;
}
#searchbar-container .searchbar-item-wrapper .searchbar-item {
  font-family: "galderglynn", Arial;
  font-size: 1.3rem;
  letter-spacing: 3px;
  word-wrap: break-word;
}
#searchbar-container .searchbar-item-wrapper .searchbar-item-active {
  position: relative;
  cursor: pointer;
  font-family: "galderglynn", Arial;
  font-size: 1.3rem;
  font-weight: 300;
  letter-spacing: 3px;
}
#searchbar-container .searchbar-item-wrapper .searchbar-item-active:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 80%;
  left: 0;
  border-bottom: 2px solid #ffcc00;
}

@media screen and (max-width: 1200px) {
  #searchbar-container {
    padding: 0 3rem;
  }
}
@media screen and (max-width: 768px) {
  #searchbar-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 0.9rem 1.5rem;
  }
  #searchbar-container .searchbar-item-wrapper .searchbar-item {
    font-size: 1.2rem;
  }
  #searchbar-container .searchbar-item-wrapper .searchbar-item-active {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 576px) {
  #searchbar-container {
    padding: 0 1.9rem;
  }
}
#summary-card .summery-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 1.5rem;
}
#summary-card .summery-item .summery-title {
  font-size: 1rem;
  min-width: 160px;
  margin: 0;
}
#summary-card .summery-item .summery-text {
  font-size: 1rem;
}
#summary-card .summery-item .summery-text #technique-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.9rem;
}
#summary-card .summery-item .summery-text #technique-container span {
  font-size: 1rem;
}
#summary-card .summery-item .summery-links {
  word-wrap: break-word;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 1.3rem;
}
#summary-card .summery-item .summery-links .webpage-item li,
#summary-card .summery-item .summery-links .webpage-item i {
  transition: all 0.2s ease-in-out;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #7ea3e8;
}
#summary-card .summery-item .summery-links .webpage-item:hover li,
#summary-card .summery-item .summery-links .webpage-item:hover i {
  transition: all 0.2s ease-in-out;
  color: #405994;
}
#summary-card .summery-item .summery-links .webpage-item .webpage-name {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}
#summary-card .summery-item .summery-links .webpage-item .webpage-icon {
  margin-bottom: 0;
}
#summary-card .summery-item .summery-links .webpage-item .webpage-icon i {
  font-size: 1.2rem;
}

#technologies-card li {
  margin-bottom: 0.9rem;
}
#technologies-card li b {
  color: #247db5;
}

#technologies-card li b {
  display: block;
  margin-bottom: 0.3rem;
}

@media only screen and (max-width: 576px) {
  #summary-card .summery-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  #summary-card .summery-item .summery-title {
    min-width: unset;
    margin: 0;
  }
  #summary-card .summery-item .summery-text {
    margin-top: 0.5rem;
  }
}
.section-container .flex-space {
  display: flex;
  flex-direction: column;
}
.section-container .space-evenly {
  justify-content: space-evenly;
}
.section-container .space-between {
  justify-content: space-between;
}
.section-container .space-center {
  justify-content: center;
}
.section-container .content-container {
  display: flex;
  gap: 1.9rem 2.3rem;
  flex-direction: row;
}
.section-container .content-container img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.section-container .content-container .start-1 {
  flex-grow: 1;
  flex-shrink: 1;
  width: 10%;
}
.section-container .content-container .start-2 {
  flex-grow: 1;
  flex-shrink: 1;
  width: 20%;
}
.section-container .content-container .start-2-5 {
  flex-grow: 1;
  flex-shrink: 1;
  width: 25%;
}
.section-container .content-container .start-3 {
  flex-grow: 1;
  flex-shrink: 1;
  width: 30%;
}
.section-container .content-container .start-4 {
  flex-grow: 1;
  flex-shrink: 1;
  width: 40%;
}
.section-container .content-container .start-5 {
  flex-grow: 1;
  flex-shrink: 1;
  width: 50%;
}
.section-container .content-container .start-6 {
  flex-grow: 1;
  flex-shrink: 1;
  width: 60%;
}
.section-container .content-container .start-7 {
  flex-grow: 1;
  flex-shrink: 1;
  width: 70%;
}
.section-container .content-container .start-8 {
  flex-grow: 1;
  flex-shrink: 1;
  width: 80%;
}
.section-container .content-container .start-9 {
  flex-grow: 1;
  flex-shrink: 1;
  width: 90%;
}
.section-container .content-container .start-10 {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
}
.section-container .container-top {
  align-items: start;
}
.section-container .container-bottom {
  align-items: end;
}
.section-container .container-center {
  align-items: center;
}

.custom-gap-bottom {
  margin-bottom: 3rem;
}

.custom-gap-top {
  margin-top: 3rem;
}

.section-spacer-small {
  margin-bottom: 3rem;
}

.section-spacer {
  margin-bottom: 3.5rem;
}

.section-spacer-large {
  margin-bottom: 4.5rem;
}

.section-spacer-extra-large {
  margin-bottom: 5rem;
}

.section-spacer-extra-extra-large {
  margin-bottom: 7rem;
}

#about-anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}

#project-anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}

@media only screen and (max-width: 1650px) {
  .section-container {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
}
@media only screen and (max-width: 1650px) {
  .section-container {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
}
@media only screen and (max-width: 1200px) {
  .section-container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media screen and (max-width: 676px) {
  .section-spacer-small {
    margin-bottom: 2.3rem;
  }
  .section-spacer {
    margin-bottom: 3rem;
  }
  .section-spacer-large {
    margin-bottom: 3.5rem;
  }
  .section-spacer-extra-large {
    margin-bottom: 4.5rem;
  }
  .section-spacer-extra-extra-large {
    margin-bottom: 5rem;
  }
  .section-spacer-heading {
    margin-bottom: 1.3rem;
  }
  .section-container {
    padding-left: 1.9rem;
    padding-right: 1.9rem;
  }
}
@media only screen and (max-width: 576px) {
  #about-anchor {
    display: block;
    position: relative;
    top: -120px;
    visibility: hidden;
  }
  #project-anchor {
    display: block;
    position: relative;
    top: -120px;
    visibility: hidden;
  }
}
@media only screen and (max-width: 1600px) {
  .section-container .breakpoint-16 {
    display: flex;
    flex-direction: column;
  }
  .section-container .breakpoint-16 .breakpoint-container-top {
    align-self: top;
  }
  .section-container .breakpoint-16 .breakpoint-container-bottom {
    align-self: end;
  }
  .section-container .breakpoint-16 .breakpoint-container-center {
    align-self: center;
  }
}
@media only screen and (max-width: 1500px) {
  .section-container .breakpoint-15 {
    display: flex;
    flex-direction: column;
  }
  .section-container .breakpoint-15 .end {
    width: 100%;
  }
  .section-container .breakpoint-15 .breakpoint-container-top {
    align-self: top;
  }
  .section-container .breakpoint-15 .breakpoint-container-bottom {
    align-self: flex-end;
  }
  .section-container .breakpoint-15 .breakpoint-container-center {
    align-self: center;
  }
}
@media only screen and (max-width: 1400px) {
  .section-container .breakpoint-14 {
    display: flex;
    flex-direction: column;
  }
  .section-container .breakpoint-14 .end {
    width: 100%;
  }
  .section-container .breakpoint-14 .breakpoint-container-top {
    align-self: top;
  }
  .section-container .breakpoint-14 .breakpoint-container-bottom {
    align-self: flex-end;
  }
  .section-container .breakpoint-14 .breakpoint-container-center {
    align-self: center;
  }
}
@media only screen and (max-width: 1300px) {
  .section-container .breakpoint-13 {
    display: flex;
    flex-direction: column;
  }
  .section-container .breakpoint-13 .end {
    width: 100%;
  }
  .section-container .breakpoint-13 .breakpoint-container-top {
    align-self: top;
  }
  .section-container .breakpoint-13 .breakpoint-container-bottom {
    align-self: flex-end;
  }
  .section-container .breakpoint-13 .breakpoint-container-center {
    align-self: center;
  }
}
a {
  margin-top: auto;
}

@media only screen and (max-width: 1200px) {
  .section-container .breakpoint-12 {
    display: flex;
    flex-direction: column;
  }
  .section-container .breakpoint-12 .end {
    width: 100%;
  }
  .section-container .breakpoint-12 .breakpoint-container-top {
    align-self: top;
  }
  .section-container .breakpoint-12 .breakpoint-container-bottom {
    align-self: flex-end;
  }
  .section-container .breakpoint-12 .breakpoint-container-center {
    align-self: center;
  }
}
@media only screen and (max-width: 1100px) {
  .section-container .breakpoint-11 {
    display: flex;
    flex-direction: column;
  }
  .section-container .breakpoint-11 .end {
    width: 100%;
  }
  .section-container .breakpoint-11 .breakpoint-container-top {
    align-self: top;
  }
  .section-container .breakpoint-11 .breakpoint-container-bottom {
    align-self: flex-end;
  }
  .section-container .breakpoint-11 .breakpoint-container-center {
    align-self: center;
  }
}
@media only screen and (max-width: 1000px) {
  .section-container .breakpoint-10 {
    display: flex;
    flex-direction: column;
  }
  .section-container .breakpoint-10 .end {
    width: 100%;
  }
  .section-container .breakpoint-10 .breakpoint-container-top {
    align-self: top;
  }
  .section-container .breakpoint-10 .breakpoint-container-bottom {
    align-self: flex-end;
  }
  .section-container .breakpoint-10 .breakpoint-container-center {
    align-self: center;
  }
}
@media only screen and (max-width: 900px) {
  .section-container .breakpoint-9 {
    display: flex;
    flex-direction: column;
  }
  .section-container .breakpoint-9 .end {
    width: 100%;
  }
  .section-container .breakpoint-9 .breakpoint-container-top {
    align-self: top;
  }
  .section-container .breakpoint-9 .breakpoint-container-bottom {
    align-self: flex-end;
  }
  .section-container .breakpoint-9 .breakpoint-container-center {
    align-self: center;
  }
}
@media only screen and (max-width: 800px) {
  .section-container .breakpoint-8 {
    display: flex;
    flex-direction: column;
  }
  .section-container .breakpoint-8 .end {
    width: 100%;
  }
  .section-container .breakpoint-8 .breakpoint-container-top {
    align-self: top;
  }
  .section-container .breakpoint-8 .breakpoint-container-bottom {
    align-self: flex-end;
  }
  .section-container .breakpoint-8 .breakpoint-container-center {
    align-self: center;
  }
}
@media only screen and (max-width: 700px) {
  .section-container .breakpoint-7 {
    display: flex;
    flex-direction: column;
  }
  .section-container .breakpoint-7 .end {
    width: 100%;
  }
  .section-container .breakpoint-7 .breakpoint-container-top {
    align-self: top;
  }
  .section-container .breakpoint-7 .breakpoint-container-bottom {
    align-self: flex-end;
  }
  .section-container .breakpoint-7 .breakpoint-container-center {
    align-self: center;
  }
}
@media only screen and (max-width: 600px) {
  .section-container .breakpoint-6 {
    display: flex;
    flex-direction: column;
  }
  .section-container .breakpoint-6 .end {
    width: 100%;
  }
  .section-container .breakpoint-6 .breakpoint-container-top {
    align-self: top;
  }
  .section-container .breakpoint-6 .breakpoint-container-bottom {
    align-self: flex-end;
  }
  .section-container .breakpoint-6 .breakpoint-container-center {
    align-self: center;
  }
}
@media only screen and (max-width: 500px) {
  .section-container .breakpoint-5 {
    display: flex;
    flex-direction: column;
  }
  .section-container .breakpoint-5 .end {
    width: 100%;
  }
  .section-container .breakpoint-5 .breakpoint-container-top {
    align-self: top;
  }
  .section-container .breakpoint-5 .breakpoint-container-bottom {
    align-self: flex-end;
  }
  .section-container .breakpoint-5 .breakpoint-container-center {
    align-self: center;
  }
}
@media only screen and (max-width: 400px) {
  .section-container .breakpoint-4 {
    display: flex;
    flex-direction: column;
  }
  .section-container .breakpoint-4 .end {
    width: 100%;
  }
  .section-container .breakpoint-4 .breakpoint-container-top {
    align-self: top;
  }
  .section-container .breakpoint-4 .breakpoint-container-bottom {
    align-self: flex-end;
  }
  .section-container .breakpoint-4 .breakpoint-container-center {
    align-self: center;
  }
}
@media only screen and (max-width: 300px) {
  .section-container .breakpoint-3 {
    display: flex;
    flex-direction: column;
  }
  .section-container .breakpoint-3 .end {
    width: 100%;
  }
  .section-container .breakpoint-3 .breakpoint-container-top {
    align-self: top;
  }
  .section-container .breakpoint-3 .breakpoint-container-bottom {
    align-self: flex-end;
  }
  .section-container .breakpoint-3 .breakpoint-container-center {
    align-self: center;
  }
}
@media only screen and (max-width: 200px) {
  .section-container .breakpoint-2 {
    display: flex;
    flex-direction: column;
  }
  .section-container .breakpoint-2 .end {
    width: 100%;
  }
  .section-container .breakpoint-2 .breakpoint-container-top {
    align-self: top;
  }
  .section-container .breakpoint-2 .breakpoint-container-bottom {
    align-self: flex-end;
  }
  .section-container .breakpoint-2 .breakpoint-container-center {
    align-self: center;
  }
}
@media only screen and (max-width: 100px) {
  .section-container .breakpoint-1 {
    display: flex;
    flex-direction: column;
  }
  .section-container .breakpoint-1 .end {
    width: 100%;
  }
  .section-container .breakpoint-1 .breakpoint-container-top {
    align-self: top;
  }
  .section-container .breakpoint-1 .breakpoint-container-bottom {
    align-self: flex-end;
  }
  .section-container .breakpoint-1 .breakpoint-container-center {
    align-self: center;
  }
}
input,
textarea,
select,
option {
  font-size: 1.2rem;
  color: #181616;
  padding: 0.5rem 0.9rem;
  font-weight: 600;
  font-family: "creato", sans-serif;
  letter-spacing: 1.5px;
  margin-bottom: 1.3rem;
  border-radius: 9px;
  border: 0;
  box-shadow: 0px 3px 7px #012236;
  width: 100%;
}

.input-type {
  width: 100%;
  margin-bottom: 1.3rem;
}
.input-type input {
  font-size: 1.2rem;
  background-color: #f0f0f0;
  cursor: pointer;
  border: none;
  box-shadow: none;
  margin-bottom: 0;
}

.form-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 3.5rem 0;
}
.form-container .form {
  width: 60%;
}

.button-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: auto;
  margin-top: 1.1rem;
  padding: 0.9rem;
}
.button-container span {
  cursor: pointer;
  margin: 0.9rem;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
}

.sub-input-title {
  font-size: 1.3rem;
  margin-bottom: 1.1rem;
}

.sub-input-container {
  margin-left: 4.5rem;
  margin-bottom: 4.5rem;
}
.sub-input-container .form-sub-listing {
  margin: 2.1rem 0;
}
.sub-input-container .form-sub-listing .form-sub-listing-container {
  display: block;
  margin: 0.9rem 0;
  position: relative;
}
.sub-input-container .form-sub-listing .form-sub-listing-container .form-sub-listing-item {
  word-wrap: break-word;
  margin: 0.3rem 0;
}
.sub-input-container .form-sub-listing .form-sub-listing-container .sub-listing-remove-button {
  position: absolute;
  right: 0.9rem;
  top: 0.5rem;
}

.keyword-input-container {
  cursor: pointer;
  margin-bottom: 3.5rem;
}
.keyword-input-container .keyword-input-item {
  font-size: 1.3rem;
  padding: 0.9rem 0.9rem;
  margin-right: 0.9rem;
  border-radius: 13px;
}
.keyword-input-container .active-keyword {
  background-color: #ffcc00;
  color: #181616;
  font-weight: 600;
}

.image-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  width: 100%;
  z-index: 9999999999999;
  background-color: #062837;
}
.image-modal-container .image-modal-scrolling {
  min-height: 100%;
}
.image-modal-container .image-modal-scrolling .image-filter-bar {
  padding: 0.5rem 0.9rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.image-modal-container .image-modal-scrolling .image-filter-bar .filter-button-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.image-modal-container .image-modal-scrolling .image-filter-bar .filter-button-container button {
  margin: 0.3rem 0.5rem;
}
.image-modal-container .image-modal-scrolling .image-collection-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1.9rem;
}
.image-modal-container .image-modal-scrolling .image-collection-container .image-wrapper img {
  width: 100%;
  object-fit: contain;
}
.image-modal-container .image-modal-scrolling .image-collection-container .image-wrapper .image-button-container {
  padding: 0.5rem 0.9rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.image-modal-container .image-modal-scrolling .image-collection-container .image-wrapper .image-button-container button {
  width: 100%;
}

.popup-container {
  height: 80%;
  max-height: 500px;
  width: 20%;
  overflow-y: auto;
  position: fixed;
  top: 110px;
  right: 2rem;
  z-index: 99999999;
}
.popup-container .popup-scrolling {
  min-height: 100%;
}
.popup-container .popup-scrolling .message-container {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
}
.popup-container .popup-scrolling .message-container .error-card {
  box-shadow: 0px 10px 20px #1b0303;
  background-color: #920e0e;
}
.popup-container .popup-scrolling .message-container .success-card {
  box-shadow: 0px 10px 20px #001a05;
  background-color: #4e7400;
}
.popup-container .popup-scrolling .message-container .message-item {
  border-radius: 9px;
  margin: 1.1rem 0;
  padding: 0.5rem 0.9rem;
  position: relative;
}
.popup-container .popup-scrolling .message-container .message-item .message-close-icon {
  cursor: pointer;
  position: absolute;
  right: 0.9rem;
  top: 0.5rem;
}
.popup-container .popup-scrolling .message-container .message-item .message-close-icon i {
  color: #181616;
}
.popup-container .popup-scrolling .message-container .message-item .message-close-icon:hover i {
  color: #f0f0f0;
}

.popup-message {
  font-size: 1.3rem;
  font-weight: 800;
  padding: 0.9rem 0;
}

@media only screen and (max-width: 1500px) {
  .popup-container {
    width: 40%;
    top: 200px;
  }
}
@media only screen and (max-width: 768px) {
  .popup-container {
    width: 70%;
    top: 300px;
  }
}
footer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 2.3rem 3.5rem;
  background-color: #143042;
  align-items: flex-start;
  align-content: flex-start;
  position: absolute;
  bottom: 0;
  width: 100%;
}
footer .footer-contacts {
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
}
footer .footer-contacts #footer-logo {
  font-family: "creato", sans-serif;
  font-size: 1.3rem;
  font-weight: 1000;
  margin-bottom: 1.1rem;
  display: inline-block;
}
footer .footer-contacts ul li {
  word-break: break-all;
  margin-bottom: 1.1rem;
  font-size: 1rem;
}
footer .footer-resources {
  padding: 0;
}

@media only screen and (max-width: 992px) {
  footer {
    text-align: center;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    justify-items: center;
    align-items: center;
    padding: 1.9rem 2.3rem;
  }
  footer .footer-contacts {
    margin: 0;
    margin-bottom: 1.5rem;
  }
  footer .footer-contacts #footer-logo {
    font-size: 1.3rem;
  }
}
#about-container #about-me-img {
  padding: 0;
  margin: 0;
}
#about-container #about-me-img img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 40%;
  object-fit: cover;
}
#about-container #about-me-img img::before {
  content: "";
  float: left;
  padding-top: 100%;
}

.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.not-found-container .not-found {
  text-align: center;
}
.not-found-container .not-found .error-text {
  font-size: 1.3rem;
}

.skeleton-thumbnail-card {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  opacity: 0.4;
  background-color: #0C3B51;
  transition: all 0.4s ease-in-out;
  transform: scale(1);
  height: 300px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
}
.skeleton-thumbnail-card .skeleton-thumbnail-header-wrapper {
  padding: 1.1rem;
  padding-bottom: 1.5rem;
  padding-right: 3rem;
  height: 30%;
  width: 100%;
}
.skeleton-thumbnail-card .skeleton-thumbnail-header-wrapper .skeleton-thumbnail-header {
  opacity: 0.1;
  animation: skeleton-text-loading 0.7s infinite;
  height: 100%;
  width: 100%;
  background-color: #1D4158;
}
.skeleton-thumbnail-card .skeleton-thumbnail-image {
  animation: skeleton-header-loading 0.7s infinite;
  opacity: 0.1;
  height: 70%;
  width: 100%;
  background-color: #0D415A;
}

#searchbar-container .skeleton-searchbar-keyword {
  animation: skeleton-text-loading 0.7s infinite;
  opacity: 0.1;
  height: 27px;
  min-width: 119px;
  background-color: #f0f0f0;
}
#searchbar-container #item-1 {
  min-width: 89px;
}
#searchbar-container #item-2 {
  min-width: 240px;
}
#searchbar-container #item-3 {
  min-width: 94px;
}

#banner-skeleton #banner-skeleton-header {
  animation: skeleton-text-loading 0.7s infinite;
  opacity: 0.1;
  background-color: #f0f0f0;
  height: 45px;
  width: 80%;
  margin-bottom: 3.5rem;
}
#banner-skeleton .banner-skeleton-text {
  animation: skeleton-text-loading 0.7s infinite;
  opacity: 0.1;
  background-color: #f0f0f0;
  height: 25px;
  width: 70%;
  margin-bottom: 3.5rem;
  margin-bottom: 0.9rem;
}
#banner-skeleton .banner-skeleton-text:last-child {
  width: 55%;
}
#banner-skeleton #skeleton-banner-resources .webpage-container {
  background-color: #0C3B51;
  opacity: 0.6;
}
#banner-skeleton #skeleton-banner-resources .webpage-container .skeleton-webpage-name {
  height: 16px;
  opacity: 0.1;
  background-color: #f0f0f0;
  animation: skeleton-text-loading 0.7s infinite;
}
#banner-skeleton #skeleton-banner-resources .webpage-container .skeleton-webpage-icon {
  height: 40px;
  opacity: 0.1;
  background-color: #f0f0f0;
  animation: skeleton-text-loading 0.7s infinite;
}

.skeleton-summary-card {
  background-color: #0C3B51;
  opacity: 0.8;
}
.skeleton-summary-card .summery-item {
  gap: 1.5rem;
}
.skeleton-summary-card .summery-item .skeleton-summery-title {
  animation: skeleton-header-loading 0.7s infinite;
  opacity: 0.2;
  height: 18px;
  width: 110px;
  background-color: #247db5;
}
.skeleton-summary-card .summery-item .skeleton-summery-text {
  animation: skeleton-text-loading 0.7s infinite;
  opacity: 0.1;
  height: 18px;
  width: 80%;
  background-color: #f0f0f0;
}

@media screen and (max-width: 300px) {
  .skeleton-thumbnail-card {
    height: 250px;
  }
}
@keyframes skeleton-text-loading {
  0% {
    background-color: rgba(241, 245, 247, 0.589);
  }
  50% {
    background-color: hsla(206, 20%, 93%, 0.815);
  }
  100% {
    background-color: rgba(241, 245, 247, 0.589);
  }
}
@keyframes skeleton-header-loading {
  0% {
    background-color: hsla(203, 44%, 46%, 0.699);
  }
  50% {
    background-color: hsla(203, 81%, 54%, 0.664);
  }
  100% {
    background-color: hsla(203, 44%, 46%, 0.699);
  }
}
@keyframes appear-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes appear-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes tilt-in {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
}
@keyframes tilt-out {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
}
@keyframes expand {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes puff-in {
  0% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
.appear-right {
  animation: appear-right 0.7s ease-in;
}

.appear-left {
  animation: appear-left 0.7s ease-in;
}

.tilt-in {
  animation: tilt-in 0.3s ease-in;
}

.tilt-out {
  animation: tilt-in 0.3s ease-out;
}

.fade-in {
  animation: expand 1.5s ease-out;
}

.puff-in {
  animation: puff-in 0.2s ease-out;
}

.response-container {
  height: 300px;
  padding: 0 3.5rem;
  margin-bottom: 4.5rem;
  min-height: 1.3rem;
}
.response-container p {
  font-size: 1.3rem;
}

.banner-resources,
.footer-resources {
  display: flex;
  padding-left: 3.5rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;
}
.banner-resources .contact-container,
.footer-resources .contact-container {
  margin-bottom: 1.5rem;
  margin-right: 1.9rem;
}
.banner-resources .contact-container li,
.footer-resources .contact-container li {
  word-break: break-all;
  font-size: 1.4rem;
  margin-bottom: 1.1rem;
}
.banner-resources .webpage-container,
.footer-resources .webpage-container {
  margin-bottom: 1.5rem;
  width: unset;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.3rem 0.9rem;
  justify-items: center;
  align-items: center;
}
.banner-resources .webpage-container .webpage-item,
.footer-resources .webpage-container .webpage-item {
  word-wrap: break-word;
  width: 140px;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.banner-resources .webpage-container .webpage-item li,
.banner-resources .webpage-container .webpage-item i,
.footer-resources .webpage-container .webpage-item li,
.footer-resources .webpage-container .webpage-item i {
  transition: all 0.2s ease-in-out;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #7ea3e8;
}
.banner-resources .webpage-container .webpage-item:hover li,
.banner-resources .webpage-container .webpage-item:hover i,
.footer-resources .webpage-container .webpage-item:hover li,
.footer-resources .webpage-container .webpage-item:hover i {
  transition: all 0.2s ease-in-out;
  color: #405994;
}
.banner-resources .webpage-container .webpage-item .webpage-name,
.footer-resources .webpage-container .webpage-item .webpage-name {
  margin-bottom: 0.9rem;
  font-size: 1rem;
}
.banner-resources .webpage-container .webpage-item .webpage-icon,
.footer-resources .webpage-container .webpage-item .webpage-icon {
  margin-bottom: 0;
}
.banner-resources .webpage-container .webpage-item .webpage-icon i,
.footer-resources .webpage-container .webpage-item .webpage-icon i {
  font-size: 1.4rem;
}
.banner-resources .webpage-container .webpage-item .footer-webpage-name,
.footer-resources .webpage-container .webpage-item .footer-webpage-name {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}
.banner-resources .webpage-container .webpage-item .footer-webpage-icon,
.footer-resources .webpage-container .webpage-item .footer-webpage-icon {
  margin-bottom: 0;
}
.banner-resources .webpage-container .webpage-item .footer-webpage-icon i,
.footer-resources .webpage-container .webpage-item .footer-webpage-icon i {
  font-size: 1.3rem;
}
.banner-resources .webpage-container-center,
.footer-resources .webpage-container-center {
  justify-content: center;
}

@media only screen and (min-width: 1650px) {
  .response-container {
    margin-bottom: 4.5rem;
  }
  .banner-resources,
  .footer-resources {
    padding-left: 0;
  }
}
@media only screen and (max-width: 1200px) {
  .response-container {
    padding: 0 3rem;
    margin-bottom: 3.5rem;
  }
  .banner-resources,
  .footer-resources {
    padding-left: 3rem;
  }
}
@media only screen and (max-width: 576px) {
  .response-container {
    height: 300px;
    padding: 0 1.9rem;
    margin-bottom: 3.5rem;
  }
  .banner-resources,
  .footer-resources {
    padding-left: 1.9rem;
  }
  .banner-resources .webpage-container,
  .footer-resources .webpage-container {
    flex-direction: column;
  }
  .banner-resources .webpage-container .webpage-item,
  .footer-resources .webpage-container .webpage-item {
    max-width: 200px;
  }
}

