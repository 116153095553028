#searchbar-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: pad-mar("small-medium") pad-mar("extra-large");
  align-items: center;
  justify-content: flex-start;
  padding: 0 pad-mar("space-two");
  .searchbar-item-wrapper {
    .searchbar-item {
      font-family: $font-family-menu, Arial;
      font-size: font-size("medium");
      letter-spacing: $letter-spacing-menu;
      word-wrap: break-word;
    }
    // Place what you want here for fancy effects...
    .searchbar-item-active {
      position: relative;
      cursor: pointer;
      font-family: $font-family-menu, Arial;
      font-size: font-size("medium");
      font-weight: $font-weight-standard;
      letter-spacing: $letter-spacing-menu;
      &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        width: 80%;
        left: 0;
        border-bottom: 2px solid color("highlight");
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  #searchbar-container {
    padding: 0 pad-mar("space-one");
  }
}

@media screen and (max-width: 768px) {
  #searchbar-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
    // justify-content:space-around;
    align-items: center;
    gap: pad-mar("small") pad-mar("medium-large");
    .searchbar-item-wrapper {
      .searchbar-item {
        font-size: font-size("standard");
      }
      .searchbar-item-active {
        font-size: font-size("standard");
      }
    }
  }
}

@media screen and (max-width: 576px) {
  #searchbar-container {
    padding: 0 pad-mar("large");
  }
}
