// Used for custom layout if needed.

// How to use:
// Wrap each section you want with section container
// Pick gird columns
// Set column start and end on each section child
// Set section spacer, and other options as you see fit
// if image, set image container 
// Tip: The bigger the columns, the smaller the image, at least in big screen.. If smaller the image will be smaller... Fuck this shit
// TIP: leave whitespace to the right or left.. A whole section before breaking...
.section-container {

  .flex-space {
    display: flex;
    flex-direction: column;

  }

  .space-evenly {
    justify-content: space-evenly;
  }

  .space-between {
    justify-content: space-between;
  }

  .space-center {
    justify-content: center;
  }

  .content-container {
    display: flex;
    gap: pad-mar("large") pad-mar("extra-extra-large");
    flex-direction: row;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }

    .start-1 {
      flex-grow: 1;
      flex-shrink: 1;
      width: calc(10%);
    }

    .start-2 {
      flex-grow: 1;
      flex-shrink: 1;
      width: calc(20%);
    }

    .start-2-5 {
      flex-grow: 1;
      flex-shrink: 1;
      width: calc(25%);
    }

    .start-3 {
      flex-grow: 1;
      flex-shrink: 1;
      width: calc(30%);
    }

    .start-4 {
      flex-grow: 1;
      flex-shrink: 1;
      width: calc(40%);
    }

    .start-5 {
      flex-grow: 1;
      flex-shrink: 1;
      width: calc(50%);
    }

    .start-6 {
      flex-grow: 1;
      flex-shrink: 1;
      width: calc(60%);
    }

    .start-7 {
      flex-grow: 1;
      flex-shrink: 1;
      width: calc(70%);
    }

    .start-8 {
      flex-grow: 1;
      flex-shrink: 1;
      width: calc(80%);
    }

    .start-9 {
      flex-grow: 1;
      flex-shrink: 1;
      width: calc(90%);
    }

    .start-10 {
      flex-grow: 1;
      flex-shrink: 1;
      width: calc(100%);
    }
  }

  .container-top {
    align-items: start;
  }

  .container-bottom {
    align-items: end;
  }

  .container-center {
    align-items: center;
  }
}

// Used for gaps when there is no gaps available, should match the up/down gap-property in content-container and p tag margin bottom..

.custom-gap-bottom {
  margin-bottom: pad-mar("large") + pad-mar("small-medium");
}

.custom-gap-top {
  margin-top: pad-mar("large") + pad-mar("small-medium");
}

.section-spacer-small {
  margin-bottom: pad-mar("space-one");
}

.section-spacer {
  margin-bottom: pad-mar("space-two");
}

.section-spacer-large {
  margin-bottom: pad-mar("space-three");
}

.section-spacer-extra-large {
  margin-bottom: pad-mar("space-four");
}

.section-spacer-extra-extra-large {
  margin-bottom: pad-mar("space-five");
}

#about-anchor {display: block; position: relative; top: -90px; visibility: hidden;}

#project-anchor {display: block; position: relative; top: -90px; visibility: hidden;}


@media only screen and (max-width: 1650px) {
  .section-container {
    padding-left: pad-mar("space-two");
    padding-right: pad-mar("space-two");
  }
}

@media only screen and (max-width: 1650px) {
  .section-container {
    padding-left: pad-mar("space-two");
    padding-right: pad-mar("space-two");
  }
}


@media only screen and (max-width: 1200px) {
  .section-container {
    padding-left: pad-mar("space-one");
    padding-right: pad-mar("space-one");
  }
}

@media screen and (max-width: 676px) {
  .section-spacer-small {
    margin-bottom: pad-mar("extra-extra-large");
  }

  .section-spacer {
    margin-bottom: pad-mar("space-one");
  }

  .section-spacer-large {
    margin-bottom: pad-mar("space-two");
  }

  .section-spacer-extra-large {
    margin-bottom: pad-mar("space-three");
  }

  .section-spacer-extra-extra-large {
    margin-bottom: pad-mar("space-four");
  }

  .section-spacer-heading {
    margin-bottom: pad-mar("medium");
  }

  .section-container {
    padding-left: pad-mar("large");
    padding-right: pad-mar("large");

  }
}

@media only screen and (max-width: 576px) {
  #about-anchor {display: block; position: relative; top: -120px; visibility: hidden;}

  #project-anchor {display: block; position: relative; top: -120px; visibility: hidden;}
  
}

// Used to break grid and go back to flex as everything will be in column when break is applied, also centered all images so they will appear in the middle
//Truth be told, all of these breakpoints isn't needed, just like all grid columns, but I like options


@media only screen and (max-width: 1600px) {
  .section-container {
    .breakpoint-16 {
      display: flex;
      flex-direction: column;

      .breakpoint-container-top {
        align-self: top;

      }

      .breakpoint-container-bottom {
        align-self: end;
      }

      .breakpoint-container-center {
        align-self: center;
      }
    }
  }
}

// breakpoint-container-bottom puts on child..

@media only screen and (max-width: 1500px) {
  .section-container {
    .breakpoint-15 {
      display: flex;
      flex-direction: column;

      .end {
        width: 100%;
      }

      .breakpoint-container-top {
        align-self: top;

      }

      .breakpoint-container-bottom {
        align-self: flex-end;
      }

      .breakpoint-container-center {
        align-self: center;
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .section-container {
    .breakpoint-14 {
      display: flex;
      flex-direction: column;

      .end {
        width: 100%;
      }

      .breakpoint-container-top {
        align-self: top;

      }

      .breakpoint-container-bottom {
        align-self: flex-end;
      }

      .breakpoint-container-center {
        align-self: center;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .section-container {
    .breakpoint-13 {
      display: flex;
      flex-direction: column;

      .end {
        width: 100%;
      }

      .breakpoint-container-top {
        align-self: top;

      }

      .breakpoint-container-bottom {
        align-self: flex-end;
      }

      .breakpoint-container-center {
        align-self: center;
      }
    }
  }
}

a {
  margin-top: auto;
}

@media only screen and (max-width: 1200px) {
  .section-container {
    .breakpoint-12 {
      display: flex;
      flex-direction: column;

      .end {
        width: 100%;
      }

      .breakpoint-container-top {
        align-self: top;

      }

      .breakpoint-container-bottom {
        align-self: flex-end;
      }

      .breakpoint-container-center {
        align-self: center;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .section-container {
    .breakpoint-11 {
      display: flex;
      flex-direction: column;

      .end {
        width: 100%;
      }

      .breakpoint-container-top {
        align-self: top;

      }

      .breakpoint-container-bottom {
        align-self: flex-end;
      }

      .breakpoint-container-center {
        align-self: center;
      }
    }
  }
}

// .end resets when breakpoint happens, setting it back to 100% width

@media only screen and (max-width: 1000px) {
  .section-container {
    .breakpoint-10 {
      display: flex;
      flex-direction: column;

      .end {
        width: 100%;
      }

      .breakpoint-container-top {
        align-self: top;

      }

      .breakpoint-container-bottom {
        align-self: flex-end;
      }

      .breakpoint-container-center {
        align-self: center;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .section-container {
    .breakpoint-9 {
      display: flex;
      flex-direction: column;

      .end {
        width: 100%;
      }

      .breakpoint-container-top {
        align-self: top;

      }

      .breakpoint-container-bottom {
        align-self: flex-end;
      }

      .breakpoint-container-center {
        align-self: center;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .section-container {
    .breakpoint-8 {

      display: flex;
      flex-direction: column;

      .end {
        width: 100%;
      }

      .breakpoint-container-top {
        align-self: top;

      }

      .breakpoint-container-bottom {
        align-self: flex-end;
      }

      .breakpoint-container-center {
        align-self: center;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .section-container {
    .breakpoint-7 {
      display: flex;
      flex-direction: column;

      .end {
        width: 100%;
      }

      .breakpoint-container-top {
        align-self: top;

      }

      .breakpoint-container-bottom {
        align-self: flex-end;
      }

      .breakpoint-container-center {
        align-self: center;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .section-container {
    .breakpoint-6 {
      display: flex;
      flex-direction: column;

      .end {
        width: 100%;
      }

      .breakpoint-container-top {
        align-self: top;

      }

      .breakpoint-container-bottom {
        align-self: flex-end;
      }

      .breakpoint-container-center {
        align-self: center;
      }
    }
  }
}


@media only screen and (max-width: 500px) {
  .section-container {
    .breakpoint-5 {
      display: flex;
      flex-direction: column;

      .end {
        width: 100%;
      }

      .breakpoint-container-top {
        align-self: top;

      }

      .breakpoint-container-bottom {
        align-self: flex-end;
      }

      .breakpoint-container-center {
        align-self: center;
      }
    }
  }
}


@media only screen and (max-width: 400px) {
  .section-container {
    .breakpoint-4 {
      display: flex;
      flex-direction: column;

      .end {
        width: 100%;
      }

      .breakpoint-container-top {
        align-self: top;

      }

      .breakpoint-container-bottom {
        align-self: flex-end;
      }

      .breakpoint-container-center {
        align-self: center;
      }
    }
  }
}

@media only screen and (max-width: 300px) {
  .section-container {
    .breakpoint-3 {
      display: flex;
      flex-direction: column;

      .end {
        width: 100%;
      }

      .breakpoint-container-top {
        align-self: top;

      }

      .breakpoint-container-bottom {
        align-self: flex-end;
      }

      .breakpoint-container-center {
        align-self: center;
      }
    }
  }
}

@media only screen and (max-width: 200px) {
  .section-container {
    .breakpoint-2 {
      display: flex;
      flex-direction: column;

      .end {
        width: 100%;
      }

      .breakpoint-container-top {
        align-self: top;

      }

      .breakpoint-container-bottom {
        align-self: flex-end;
      }

      .breakpoint-container-center {
        align-self: center;
      }
    }
  }
}

@media only screen and (max-width: 100px) {
  .section-container {
    .breakpoint-1 {
      display: flex;
      flex-direction: column;

      .end {
        width: 100%;
      }

      .breakpoint-container-top {
        align-self: top;

      }

      .breakpoint-container-bottom {
        align-self: flex-end;
      }

      .breakpoint-container-center {
        align-self: center;
      }
    }
  }
}