.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .not-found {
    text-align: center;
    .error-text {
      font-size: font-size("medium");
    }
  }
}
