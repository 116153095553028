.response-container {
  height: 300px;
  padding: 0 pad-mar("space-two");
  margin-bottom: pad-mar("space-three");
  min-height: font-size("medium");
  p {
    font-size: font-size("medium");
  }
}

.banner-resources,
.footer-resources {
  // width: 100%;
  display: flex;
  padding-left: pad-mar("space-two");
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;
  .contact-container {
    margin-bottom: pad-mar("medium-large");
    margin-right: pad-mar("large");
    li {
      word-break: break-all;
      // If number is added to contacts, use this this
      // font-size: font-size("medium");
      font-size: font-size("medium-large");
      margin-bottom: pad-mar("small-medium");
    }
  }
  .webpage-container {
    margin-bottom: pad-mar("medium-large");
    width: unset;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: pad-mar("medium") pad-mar("small");
    // justify-items: flex-end;
    // align-items: flex-end;
    justify-items: center;
    align-items: center;
    .webpage-item {
      word-wrap: break-word;
      width: 140px;
      cursor: pointer;
      text-align: center;
      display: flex;
      flex-direction: column;
      li,
      i {
        transition: all 0.2s ease-in-out;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: color("hyperlink");
      }
      &:hover {
        li,
        i {
          transition: all 0.2s ease-in-out;
          color: color("hyperlinkhover");
        }
      }
      .webpage-name {
        margin-bottom: pad-mar("small");
        font-size: font-size("small");
      }
      .webpage-icon {
        margin-bottom: 0;
        i {
          font-size: font-size("medium-large");
        }
      }
      .footer-webpage-name {
        margin-bottom: pad-mar("extra-small");
        font-size: font-size("extra-small");
      }
      .footer-webpage-icon {
        margin-bottom: 0;
        i {
          font-size: font-size("medium");
        }
      }
    }
  }
  // .webpage-container-left {
  //   justify-content: flex-start;
  //   justify-items: center;
  //   align-items: center;
  // }
  .webpage-container-center {
    justify-content: center;
  }
}

@media only screen and (min-width: 1650px) {
  .response-container {
    // padding: 0;
    margin-bottom: pad-mar("space-three");
  }

  .banner-resources,
  .footer-resources {
    padding-left: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .response-container {
    padding: 0 pad-mar("space-one");
    margin-bottom: pad-mar("space-two");
  }

  .banner-resources,
  .footer-resources {
    padding-left: pad-mar("space-one");
  }
}

@media only screen and (max-width: 576px) {
  .response-container {
    height: 300px;
    padding: 0 pad-mar("large");
    margin-bottom: pad-mar("space-two");
  }

  .banner-resources,
  .footer-resources {
    padding-left: pad-mar("large");
    .webpage-container {
      flex-direction: column;
      .webpage-item {
        max-width: 200px;
      }
    }
  }
}
