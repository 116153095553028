// .slide-enter,
// .slide-leave {
//     transition: all 0.15s ease;
// }

// .slide-enter-start,
// .slide-leave-end {
//     transform: translateX(-100px);
// }

@keyframes skeleton-text-loading {
  0% {
    background-color: rgba(241, 245, 247, 0.589);
  }
  50% {
    background-color: hsla(206, 20%, 93%, 0.815);
  }
  100% {
    background-color: rgba(241, 245, 247, 0.589);
  }
}

@keyframes skeleton-header-loading {
  0% {
    background-color: hsla(203, 44%, 46%, 0.699);
  }
  50% {
    background-color: hsla(203, 81%, 54%, 0.664);
  }
  100% {
    background-color: hsla(203, 44%, 46%, 0.699);
  }
}

@keyframes appear-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes appear-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}


@keyframes tilt-in {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
}

@keyframes tilt-out {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
}

@keyframes expand {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes puff-in {
  0% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}


.appear-right {
  animation: appear-right 0.7s ease-in;
}

.appear-left {
  animation: appear-left 0.7s ease-in;
}


.tilt-in {
  animation: tilt-in 0.3s ease-in;
}

.tilt-out {
  animation: tilt-in 0.3s ease-out;
}

.fade-in {
  animation: expand 1.5s ease-out;
}

.puff-in  {
  animation: puff-in  0.2s ease-out;
}

