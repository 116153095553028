input,
textarea,
select,
option {
  font-size: font-size("standard");
  color: color("black");
  padding: pad-mar("extra-small") pad-mar("small");
  font-weight: $font-weight-medium;
  font-family: $font-family-base, sans-serif;
  letter-spacing: 1.5px;
  margin-bottom: pad-mar("medium");
  border-radius: $border-radius-standard;
  border: 0;
  box-shadow: $button-box-shadow;
  width: 100%;
}

// Because CSS still can't work on the input file after 30 years.
.input-type {
  width: 100%;
  margin-bottom: pad-mar("medium");
  input {
    font-size: font-size("standard");
    background-color: color("base");
    cursor: pointer;
    border: none;
    box-shadow: none;
    margin-bottom: 0;
  }
}

.form-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: pad-mar("space-two") 0;
  .form {
    width: 60%;
  }
}

.button-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: auto;
  margin-top: pad-mar("small-medium");
  padding: pad-mar("small");
  button {
  }
  span {
    cursor: pointer;
    margin: pad-mar("small");
  }
}

label {
  display: inline-block;
  margin-bottom: pad-mar("extra-small");
  font-size: font-size("medium");
}

.sub-input-title {
  font-size: font-size("medium");
  margin-bottom: pad-mar("small-medium");
}

.sub-input-container {
  margin-left: pad-mar("space-three");
  margin-bottom: pad-mar("space-three");
  .form-sub-listing {
    margin: pad-mar("extra-large") 0;
    .form-sub-listing-container {
      display: block;
      margin: pad-mar("small") 0;
      position: relative;
      .form-sub-listing-item {
        word-wrap: break-word;
        margin: pad-mar("extra-extra-small") 0;
      }
      .sub-listing-remove-button {
        position: absolute;
        right: pad-mar("small");
        top: pad-mar("extra-small");
      }
    }
  }
}
.keyword-input-container {
  cursor: pointer;
  margin-bottom: pad-mar("space-two");
  .keyword-input-item {
    font-size: font-size("medium");
    padding: pad-mar("small") pad-mar("small");
    margin-right: pad-mar("small");
    border-radius: $border-radius-big;
  }
  .active-keyword {
    background-color: color("highlight");
    color: color("black");
    font-weight: $font-weight-medium;
  }
}