button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  font-size: font-size("standard");
  font-weight: $font-weight-medium;
  background-color: color("shadow");
  margin: pad-mar("extra-extra-small") 0;
  padding: pad-mar("extra-small") pad-mar("small");
  box-shadow: $button-box-shadow;
  border-radius: $border-radius-big;
  &:hover {
    background-color: color("btnhover");
  }
}

button {
  font-size: font-size("standard");
  font-weight: $font-weight-medium;
  background-color: color("shadow");
  margin: pad-mar("extra-extra-small") 0;
  padding: pad-mar("extra-small") pad-mar("small");
  &:hover {
    background-color: color("btnhover");
  }
  max-width: 200px;
  width: 100%;
}

.delete-button {
  background-color: color("danger");
  box-shadow: $button-delete-box-shadow;
  &:hover {
    background-color: color("warning");
  }
}

.submit-button {
  background-color: color("success");
  box-shadow: $button-successes-box-shadow;
  &:hover {
    background-color: color("success-hover");
  }
}

.go-back {
  font-size: font-size("standard");
  font-weight: $font-weight-bold;
  color: color("base");
  margin: pad-mar("extra-extra-small") 0;
  padding: pad-mar("extra-small") pad-mar("small");
  &:hover {
    color: color("btnhover");
  }
}

button:disabled,
button[disabled] {
  background: transparent
    linear-gradient(180deg, color("disabled") 100%, color("muted") 0%) 0% 0%
    no-repeat padding-box;
  color: color("black");
  opacity: 0.7;
  box-shadow: $button-successes-box-disabled;
  &:hover {
    cursor: not-allowed;
    background-color: color("muted-dark");
  }
}

.remove-disabled-styling {
  background: transparent;
}

button:disabled::before,
button[disabled]::before {
  color: color("muted");
}
