.image-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  width: 100%;
  z-index: 9999999999999;
  background-color: color("background");
  .image-modal-scrolling {
    min-height: 100%;
    .image-filter-bar {
      padding: pad-mar("extra-small") pad-mar("small");
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .filter-button-container {
        flex: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        button {
          margin: pad-mar("extra-extra-small") pad-mar("extra-small");
        }
      }
    }
    .image-collection-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 1rem;
      padding: pad-mar("large");
      .image-wrapper {
        img {
          width: 100%;
          object-fit: contain;
        }
        .image-button-container {
          padding: pad-mar("extra-small") pad-mar("small");
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          button {
            width: 100%;
          }
        }
      }
    }
  }
}
