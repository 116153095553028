footer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: pad-mar("extra-extra-large") pad-mar("space-two");
  background-color: color("secondary");
  align-items: flex-start;
  align-content: flex-start;
  position: absolute;
  bottom: 0;
  width: 100%;

  .footer-contacts {
    margin-bottom: pad-mar("medium-large");
    margin-right: pad-mar("medium-large");
    #footer-logo {
      font-family: $font-family-base, sans-serif;
      font-size: font-size("medium");
      font-weight: $font-weight-extra-bold;
      margin-bottom: pad-mar("small-medium");
      display: inline-block;
    }
    ul {
      li {
        word-break: break-all;
        margin-bottom: pad-mar("small-medium");
        font-size: font-size("small");
      }
    }
  }
  .footer-resources {
    padding: 0;
  }
}

@media only screen and (max-width: 992px) {
  // footer {
  //   display: flex;
  //   flex-direction: row;
  //   flex-wrap: wrap;
  //   justify-content: space-between;
  //   padding: pad-mar("extra-large") pad-mar("space-one");
  //   background-color: color("secondary");
  //   align-items: flex-start;
  //   align-content: flex-start;
  //   position: absolute;
  //   bottom: 0;
  //   width: 100%;

  //   .footer-contacts {
  //     padding: 0;
  //     #footer-logo {
  //       font-family: $font-family-base;
  //       font-size: font-size("medium");
  //       font-weight: $font-weight-extra-bold;
  //       margin-bottom: pad-mar("small-medium");
  //       display: inline-block;
  //     }
  //     ul {
  //       li {
  //         margin-bottom: pad-mar("small-medium");
  //         font-size: font-size("small");
  //       }
  //     }
  //   }
  // }
}

@media only screen and (max-width: 992px) {
  footer {
    text-align: center;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    justify-items: center;
    align-items: center;
    padding: pad-mar("large") pad-mar("extra-extra-large");
    .footer-contacts {
      margin: 0;
      margin-bottom: pad-mar("medium-large");
      #footer-logo {
        font-size: font-size("medium");
      }
    }
  }
}
