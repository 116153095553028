header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: pad-mar("small-medium") pad-mar("extra-extra-large");
  background-color: color("secondary");
  position: fixed;
  // position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999999;

  .logo {
    font-family: $font-family-base, sans-serif;
    font-size: font-size("large");
    font-weight: $font-weight-extra-bold;

    &:hover {
      color: color("hyperlinkhover");
    }
  }

  nav {
    flex: 1;
    justify-content: end;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: pad-mar("large");
    align-items: center;

    a {
      color: color("base");
      text-decoration: none;
      font-family: $font-family-menu, Arial;
      font-weight: $font-weight-narrow;
      font-size: font-size("standard");
      letter-spacing: $letter-spacing-menu;
    }
  }
}

@media only screen and (max-width: 768px) {
  header {
    padding: pad-mar("small") pad-mar("medium");
    background-color: color("secondary");

    nav {
      gap: pad-mar("medium-large");

      a {
        font-size: font-size("small");
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  header {
    padding: pad-mar("small-medium") pad-mar("small");
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    .logo {
      font-size: font-size("medium");
      text-align: center;
    }

    nav {
      flex-wrap: wrap;
      gap: pad-mar("small-medium");
      margin-top: pad-mar("small-medium");
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 300px) {
  header {
    min-width: 299px;
  }
}

@media only screen and (max-width: 1500px) {
  #admin-header {
    flex-direction: column;
    flex-wrap: wrap;

    nav {
      margin-top: pad-mar("small");
      justify-content: space-evenly;
      gap: pad-mar("small-medium");
    }
  }
}