#about-container {
  #about-me-img {
    padding: 0;
    margin: 0;
  
    img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
      border-radius: 40%;    
      object-fit: cover;
      &::before {
        content:'';
        float:left;
        padding-top:100%;
      }
    }
  }
}
