* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

#root {
  position: relative;
  min-height: 100vh;
}

// Keeps track of footer and header and minor changes in layout to make app responsive
#layout-container {
  padding-bottom: 270px;
  padding-top: 70px;
  background-image: url("/public/assets/background-web.svg");
  background-position: center top;
  background-size: 100% auto;
  overflow-x: hidden;
}
// background-image: url("/public/assets/background-table.svg");

html {
  scroll-behavior: smooth;
  overflow: hidden;
  width: 100%;
  scrollbar-width: none;
}

body {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: color("background");
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.unordered-list-reset {
  list-style-type: none;
  .open-location-button {
    margin-bottom: 0;
  }
}

.button-reset {
  all: unset;
  cursor: pointer;
  color: color("base");
  font-size: font-size("standard");
  font-family: $font-family-base;
}

/* I will not get a tiny api key or what this plugging is trying to sell me..! */
.tox-notifications-container {
  display: none;
}
img {
  width: 100%;
}

// Padding to keep footer bellow app content
@media screen and (max-width: 992px) {
  #layout-container {
    padding-top: 60px;
    padding-bottom: 340px;
    background-image: url("/public/assets/background-table.svg");
  }
}

@media screen and (max-width: 576px) {
  #layout-container {
    padding-top: 100px;
    padding-bottom: 500px;
  }
}

// If anyone insists on using a 300px device, then they might as well scroll.
@media screen and (max-width: 300px) {
  #layout-container {
    width: 300px;
    padding-bottom: 600px;
  }
}
