.app-max-container {
  max-width: 1540px; 
  margin: 0 auto;
}

// Used to set max on card so that margins are ignored to left and right, unlike app-max-container
.card-max-container {
  max-width: 2200px;
  margin: 0 auto;
}

@media only screen and (min-width: 2200px) {
  .card-max-container {
    border-radius: $border-radius-standard;
  }
}