.card {
  background-color: color("primary");
  box-shadow: $box-shadow-big;
  padding: pad-mar("extra-large") pad-mar("space-two");
}

.card-large {
  background-color: color("primary");
  box-shadow: $box-shadow-big;
  padding: 5rem 6rem;
}

.card-no-side-padding {
  background-color: color("primary");
  box-shadow: $box-shadow-big;
  padding: pad-mar("extra-large") 0;
}

.card-no-padding {
  background-color: color("primary");
  box-shadow: $box-shadow-big;
}

.card-small {
  background-color: color("primary");
  box-shadow: $box-shadow-big;
  padding: pad-mar("medium") pad-mar("space-two");
}

.card-left {
  border-radius: 0 $border-radius-standard $border-radius-standard 0;
  width: 75%;
  margin-right: 25%;
}

.card-right {
  border-radius: $border-radius-standard 0 0 $border-radius-standard;
  width: 75%;
  margin-left: 25%;
}

.card-center {
  border-radius: $border-radius-standard;
}

.card-center-fullwidth {
  border-radius: 0;
}


@media only screen and (min-width: 1540px) {

  .card-center-fullwidth {
    border-radius: $border-radius-standard;
  }
  .card-right {
    border-radius: $border-radius-standard;
  }

  .card-left {
    border-radius: $border-radius-standard;
  }
  .card-center {
    border-radius: $border-radius-standard;
  }
}

@media only screen and (max-width: 1200px) {
  .card {
    padding: pad-mar("large") pad-mar("space-one");
  }

  .card-small {
    padding: pad-mar("small-medium") pad-mar("space-one");
  }

  .card-large {
    background-color: color("primary");
    box-shadow: $box-shadow-big;
    padding: 3rem 4rem;
  }

  .card-left {
    width: 85%;
    margin-right: 15%;
  }

  .card-right {
    width: 85%;
    margin-left: 15%;
  }
}

@media only screen and (max-width: 576px) {
  .card {
    padding: pad-mar("large") pad-mar("large");
  }

  .card-small {
    padding: pad-mar("medium") pad-mar("large");
  }

  .card-left {
    width: 95%;
    margin-right: 5%;
  }

  .card-right {
    width: 95%;
    margin-left: 5%;
  }
  .card-large {
    background-color: color("primary");
    box-shadow: $box-shadow-big;
    padding: 2rem 3rem;
  }
}
