.skeleton-thumbnail-card {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  opacity: 0.4;
  background-color: color("skeleton-thumbnail-background");
  transition: all 0.4s ease-in-out;
  transform: scale(1);
  height: 300px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  .skeleton-thumbnail-header-wrapper {
    padding: pad-mar("small-medium");
    padding-bottom: pad-mar("medium-large");
    padding-right: pad-mar("space-one");
    height: 30%;
    width: 100%;
    .skeleton-thumbnail-header {
      opacity: 0.1;
      animation: skeleton-text-loading 0.7s infinite;
      height: 100%;
      width: 100%;
      background-color: color("skeleton-thumbnail-header");
    }
  }
  .skeleton-thumbnail-image {
    animation: skeleton-header-loading 0.7s infinite;
    opacity: 0.1;
    height: 70%;
    width: 100%;
    background-color: color("skeleton-thumbnail-image");
  }
}

#searchbar-container {
  .skeleton-searchbar-keyword {
    animation: skeleton-text-loading 0.7s infinite;
    opacity: 0.1;
    height: 27px;
    min-width: 119px;
    background-color: color("base");
  }
  #item-1 {
    min-width: 89px;
  }
  #item-2 {
    min-width: 240px;
  }
  #item-3 {
    min-width: 94px;
  }
}

#banner-skeleton {
  #banner-skeleton-header {
    animation: skeleton-text-loading 0.7s infinite;
    opacity: 0.1;
    background-color: color("base");
    height: 45px;
    width: 80%;
    margin-bottom: pad-mar("space-two");
  }
  .banner-skeleton-text {
    animation: skeleton-text-loading 0.7s infinite;
    opacity: 0.1;
    background-color: color("base");
    height: 25px;
    width: 70%;
    margin-bottom: pad-mar("space-two");
    margin-bottom: pad-mar("small");
  }
  .banner-skeleton-text:last-child {
    width: 55%;
  }
  #skeleton-banner-resources {
    .webpage-container {
      background-color: color("skeleton-thumbnail-background");
      opacity: 0.6;
      .skeleton-webpage-name {
        height: 16px;
        // max-width: 100px;
        opacity: 0.1;
        background-color: color("base");
        animation: skeleton-text-loading 0.7s infinite;
      }
      .skeleton-webpage-icon {
        height: 40px;
        // max-width: 50px;
        opacity: 0.1;
        background-color: color("base");
        animation: skeleton-text-loading 0.7s infinite;
      }
    }
  }
}

.skeleton-summary-card {
  background-color: color("skeleton-thumbnail-background");
  opacity: 0.8;
  .summery-item {
    gap: pad-mar("medium-large");
    .skeleton-summery-title {
      animation: skeleton-header-loading 0.7s infinite;
      opacity: 0.2;
      height: 18px;
      width: 110px;
      background-color: color("heading");
    }
    .skeleton-summery-text {
      animation: skeleton-text-loading 0.7s infinite;
      opacity: 0.1;
      height: 18px;
      width: 80%;
      background-color: color("base");
    }
  }
}

@media screen and (max-width: 300px) {
  .skeleton-thumbnail-card {
    height: 250px;
  }
}
