.thumbnail-cards-container {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0 pad-mar("space-two");
  gap: pad-mar("large") pad-mar("medium-large");
  .thumbnail-card {
    transition: all 0.4s ease-in-out;
    transform: scale(1);
    height: 300px;
    cursor: pointer;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    // image-rendering: -moz-crisp-edges; /* Firefox */
    // image-rendering: -o-crisp-edges; /* Opera */
    // image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    // image-rendering: crisp-edges;
    // -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .thumbnail-header-name {
      font-size: font-size("medium");
      padding: pad-mar("small-medium");
      padding-bottom: pad-mar("medium-large");
      color: color("base");
      margin: 0;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      // overflow: hidden;
    }

    .thumbnail-technique-container {
      border-radius: $border-radius-standard 0 0 $border-radius-standard;
      width: 90%;
      margin-left: 10%;
      padding: pad-mar("small-medium");
      padding-bottom: pad-mar("medium-large");
      position: absolute;
      right: -470px;
      bottom: pad-mar("medium");
      transition: all 0.6s ease-in-out;
      .thumbnail-header-techniques,
      .thumbnail-metainfo {
        font-size: font-size("small");
        margin-bottom: pad-mar("extra-small");
      }
      .thumbnail-keyword-container {
        .thumbnail-keyword-item {
          font-size: font-size("extra-small");
        }
        gap: pad-mar("extra-small");
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    img {
      object-fit: cover;
      width: 100%;
      // height: 100%;
    }
    &:hover {
      transition: all 0.4s ease-in-out;
      z-index: 99999;
      transform: scale(1.2);
      height: 300px;
      .thumbnail-technique-container {
        transition: all 0.6 ease-in-out;
        position: absolute;
        right: 0;
        bottom: pad-mar("medium");
      }
    }
  }
}

#records-container {
  word-break: break-all;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0 pad-mar("space-two");
  gap: pad-mar("large") pad-mar("medium-large");
  .record-card {
    // Always calc with final width on each item
    // width: calc(25% - #{pad-mar("medium-large")});
    margin-bottom: pad-mar("large");
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .record-header-name {
      word-wrap: break-word;
      font-size: font-size("medium");
      margin-bottom: pad-mar("small-medium");
    }
    .record-metainfo {
      word-wrap: break-word;
      font-size: font-size("small");
      margin-bottom: pad-mar("small");
    }
  }
}

@media screen and (max-width: 1200px) {
  .thumbnail-cards-container {
    padding: 0 pad-mar("space-one");
    grid-template-columns: 1fr 1fr 1fr;
  }

  #records-container {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 pad-mar("space-one");
  }
}

@media screen and (max-width: 992px) {
  .thumbnail-cards-container {
    grid-template-columns: 1fr 1fr;
  }

  #records-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 576px) {
  #records-container {
    grid-template-columns: 1fr;
    padding: 0 pad-mar("large");
  }

  .thumbnail-cards-container {
    grid-template-columns: 1fr;
    padding: 0 pad-mar("large");
    // gap: pad-mar("large") pad-mar("medium-large");
    .thumbnail-card {
      .thumbnail-header-name {
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .thumbnail-cards-container {
    .thumbnail-card {
      height: 250px;
      .thumbnail-header-name {
        font-size: font-size("medium");
        padding: pad-mar("medium");
        margin: 0;
      }
    }
  }
}
